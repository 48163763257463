<template>
  <v-list class="py-0">
    <v-list-item @click="abrirAcompanhamento"
                 v-if="mostrarAcompanhamento">
      <v-list-item-action>
        <v-icon>assignment_turned_in</v-icon>
      </v-list-item-action>
      <v-list-item-title>{{ $t('label.acompanhamento') }}</v-list-item-title>
    </v-list-item>

    <acao-fluxo
      ref="acaoAprovar"
      icone="thumb_up"
      :texto-acao="$t('label.aprovar')"
      :status="status"
      :is-contrato="isContrato"
      :id-contrato="idContrato"
      :data-inicio-contrato="contratoSelecionado.dtaVigenciaInicio"
      :data-fim-contrato="contratoSelecionado.dtaVigenciaFim"
      :layoutGrid="true"
      v-if="podeAprovar"
      @AcaoFluxo_executarAcao="aprovar">
    </acao-fluxo>

    <acao-fluxo
      ref="acaoEmAnaliseList"
      icone="announcement"
      :texto-acao="$t('label.solicitar_analise')"
      :status="status"
      :is-contrato="isContrato"
      :id-contrato="idContrato"
      :layoutGrid="true"
      :validar-justificativa="true"
      v-if="podeAprovar"
      @AcaoFluxo_executarAcao="enviarAnalise">
    </acao-fluxo>

    <acao-fluxo
      ref="acaoReprovar"
      icone="thumb_down"
      :texto-acao="$t('label.reprovar')"
      :status="status"
      :is-contrato="isContrato"
      :id-contrato="idContrato"
      :layoutGrid="true"
      :validar-justificativa="true"
      v-if="podeAprovar"
      @AcaoFluxo_executarAcao="reprovar">
    </acao-fluxo>

    <acao-fluxo
      v-if="mostrarJustificativa"
      ref="acaoMostrarJustificativa"
      icone="info"
      :mostrarRodape="false"
      :is-contrato="isContrato"
      :id-contrato="idContrato"
      :layoutGrid="true"
      @click.native="preencheInformacoesJustificativa"
      :texto-acao="$tc('label.justificativa', 1)"
      @AcaoFluxo_menuAberto="preencheInformacoesJustificativa">
    </acao-fluxo>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    />
  </v-list>
</template>

<script>
import PlanejamentoAcaoFluxoFuncoes from './PlanejamentoAcaoFluxoFuncoes';

export default {
  name: 'PlanejamentoAcaoFluxoList',
  mixins: [PlanejamentoAcaoFluxoFuncoes],
};
</script>

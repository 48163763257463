<style>
.PlanejamentoAcaoList__Modal .headline {
  width: 100%;
  text-align: center;
}
</style>
 <template>
  <div>
    <v-card class="PlanejamentoAcaoList__Modal">
      <v-card-title>
        <span class="headline">
          {{ $t('title.selecione_tipo_acao') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="filtroTipo"
          :label="$t('label.filtrar')"
          clearable
          append-icon="search">
        </v-text-field>
        <v-list class="PlanejamentoAcaoList__Lista">
          <v-list-item @click="selecionadoTipoAcao(tipo)"
            v-for="(tipo, index) in tiposAcaoFiltrados"
            :key="index"
            class="PlanejamentoAcaoList__TipoAcao"
            v-if="!tipo.atingiuMaximo"
            ripple>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{tipo.nome}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">arrow_forward</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click.native="cancelarCriacaoAcao">{{ $t('label.cancelar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

export default {
  data() {
    return {
      filtroTipo: '',
    };
  },
  props: {
    tiposAcaoContrato: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tiposAcaoFiltrados() {
      return this.tiposAcaoContrato.filter((tipo) => !tipo.atingiuMaximo
        && tipo.nome.toLowerCase().includes(this.filtroTipo.toLowerCase()));
    },
  },
  methods: {
    cancelarCriacaoAcao() {
      this.$emit('PlanejamentoAcaoModalNovaAcao__FecharModalNovaAcao');
    },
    selecionadoTipoAcao(acao) {
      this.$emit('PlanejamentoAcaoModalNovaAcao__CadastrarModalNovaAcao', acao);
    },
  },
};
</script>

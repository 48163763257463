<template>
  <div>
    <v-data-table
      class="elevation-1"
      :headers="cabecalhoPorTipoAcao()"
      :items="copiaAcoes"
      :search="filtro"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template
        v-slot:item.acoes="{ item }">
        <v-menu offset-y :close-on-content-click="false" v-model="item.modalLista" eager>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item
              v-if="podeClonar(acoes[item.index])"
              @click="copiaAcao(item.index)">
              <v-list-item-action>
                <v-icon>library_add</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.copiar_acao', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="podeExcluir(acoes[item.index]) && canEdit"
              @click="confirmaRemoverAcao(item.index)">
              <v-list-item-action>
                <v-icon>remove_circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.excluir_a_acao', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="editaVisualizaAcao(acoes[item.index], item.index)">
              <v-list-item-action>
                <v-icon v-if="permiteEditar(acoes[item.index]) && canEdit">edit</v-icon>
                <v-icon v-else>details</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ permiteEditar(acoes[item.index]) && canEdit ? $t('label.editar_a_acao') : $tc('label.detalhe', 2) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="podeCancelar(acoes[item.index]) && canEdit"
              @click="confirmaCancelarAcao(item.index)">
              <v-list-item-action>
                <v-icon>clear</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="podeCancelarFluxo(acoes[item.index]) && canEdit"
              @click="confirmaCancelarFluxo(item.index)">
              <v-list-item-action>
                <v-icon>clear</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="permiteSolicitarAprovacao(acoes[item.index])
               && canEdit
                && usuarioPodeSolicitarEsseTipoAcao(acoes[item.index])"
              @click="solicitarAprovacao(acoes[item.index])">
              <v-list-item-action>
                <v-icon>send</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.solicitar_aprovacao', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="acaoEmAnalise(acoes[item.index])
               && canEdit
               && !temFluxoContrato"
              @click="confirmarExecutarAnalise(item.index)">
              <v-list-item-action>
                <v-icon>edit</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <planejamento-acao-fluxo-list
            :ref="`acaoFluxo${item.index}`"
            v-if="acoes[item.index]"
            :key="acoes[item.index].id"
            :idAcao="acoes[item.index].id"
            :acao-de-contrato="true"
            :is-contrato="true"
            :id-contrato="idContrato"
            :status="acoes[item.index].passoGeral.status"
            :contrato-possui-fluxo="temFluxoContrato"
            :acao-possui-fluxo="acoes[item.index].passoGeral.temFluxo"
            @recarregar="recarregarDados(acoes[item.index].id, item.index)"
            @PlanejamentoAcaoFluxoFuncoes__justificativa
                ="(j) => preencherJustificativa(acoes[item.index], j)"/>
        </v-menu>
      </template>
      <template v-slot:item.dtaInicio="{ item }">
        {{ item.dtaInicio }}
      </template>
      <template v-slot:item.dtaFim="{ item }">
        {{ item.dtaFim }}
      </template>
      <template v-slot:item.arvoreProduto="{ item }">
        {{ item.arvoreProduto }}
      </template>
      <template v-slot:item.status="{ item }">
        <status :status="item.status || 'EM_CADASTRO'" :tooltip="item.status || 'EM_CADASTRO'"></status>
      </template>
    </v-data-table>
    <confirm
      ref="mensagensAvisoDialog"
      :message="mensagemAviso"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
    <confirm
      ref="removerAcaoDialog"
      :message="msgRemocaoAcao"
      :persistent="true"
      @agree="removeAcao"/>
    <confirm
      ref="executarAnaliseDialog"
      :message="msgExecutarAnalise"
      :persistent="true"
      @agree="executarAnalise"/>
    <confirm
      ref="fluxoSemUsuarioClienteDialog"
      :message="msgFluxoSemAprovador"
      :persistent="true"
      @agree="iniciaFluxo"/>
    <planejamento-acao-form-usuario-cliente
      ref="modalUsuarioCliente"
      :title="$tc('title.selecao_aprovador_fornecedor', 1)"
      :passoCliente="passoCliente"
      @selecionarUsuarioCliente="selecionaAprovadorPassoClienteFornecedor"/>
    <PlanejamentoContratoDialogCancelarAcao
      ref="cancelarAcaoDialog"
      :cancelarAcao="funcCancelar"
      :mensagem="mensagemCancelar"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../../../common/functions/roles-computed-generator';
import Status from '../../../../shared-components/Status';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoContratoFormAcoesControleItens from './PlanejamentoContratoFormAcoesControleItens';
import PlanejamentoContratoDialogCancelarAcao from './PlanejamentoContratoDialogCancelarAcao';
import PlanejamentoAcaoFluxoList from '../../../planejamento-acao/PlanejamentoAcaoFluxoList';
import PlanejamentoAcaoFormUsuarioCliente from '../../../planejamento-acao/form/PlanejamentoAcaoFormUsuarioCliente';

export default {
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    Status,
    Confirm,
    PlanejamentoContratoDialogCancelarAcao,
    PlanejamentoAcaoFluxoList,
  },
  mixins: [PlanejamentoContratoFormAcoesControleItens],
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
    idContrato() {
      const { idContrato } = this.$route.params;
      if (!idContrato) return null;
      return Number(idContrato);
    },
  },
  props: {
    acoes: {
      type: Array,
      required: true,
    },
    acoesFormatadas: {
      type: Array,
      required: true,
    },
    tiposAcaoSolicitaveis: {
      type: Array,
      required: true,
    },
    somenteLeitura: Boolean,
  },
  watch: {
    acoes(value) {
      if (value && value.length
        && (!this.copiaAcoes || !this.copiaAcoes.length)) {
        this.setAcoes();
      }
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      copiaAcoes: [],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    montaListaItemsProduto(item) {
      const { passoGeral } = item;
      const { produto } = passoGeral;
      return (produto || []).reduce((acc, cur, idx) => (
        idx === 0 ? cur.nomProduto : `${acc}, ${cur.nomProduto}`
      ), '');
    },
    cabecalhoPorTipoAcao() {
      const arr = [
        { text: '', value: 'acoes', sortable: false },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
        { text: this.$tc('label.tipo_acao', 1), value: 'nomeTipoAcao', sortable: false },
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: false,
          class: 'PlanejamentoContratoFormAcoesControleLista__ColunaGrande',
        },
        { text: this.$tc('label.data_inicio', 1), value: 'dtaInicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'dtaFim', sortable: false },
        {
          text: this.$tc('label.produto', 1),
          value: 'arvoreProduto',
          sortable: false,
          class: 'PlanejamentoContratoFormAcoesControleLista__ColunaGrande',
        },
      ];
      return arr;
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const { idContrato } = this.$route.params;
      const params = {
        idContrato,
        idPasso,
        idUsuario,
      };
      const resource = this.indModoVarejo
        ? this.workflowAcaoResource.associaUsuarioFornecedorAprovador
        : this.workflowAcaoResource.associaUsuarioClienteAprovador;
      resource(params, params)
        .then(() => this.iniciaFluxo())
        .catch((err) => this.$error(this, err));
    },
    iniciaFluxo() {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.iniciaFluxo({ idContrato }, { idContrato })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    ajustarFormatoData(item, key) {
      if (this.herdarPeriodoAcao) {
        return this.moment(item.passoComportamento[key]).format('DD/MM/YYYY');
      }
      return this.moment(item.passoGeral[key]).format('DD/MM/YYYY');
    },
    setAcoes() {
      this.copiaAcoes = this.acoes.map((el, index) => {
        const obj = {
          index,
          produto: this.montaListaItemsProduto(el),
          status: el.passoGeral.status || 'EM_CADASTRO',
          nomeTipoAcao: el.nomeTipoAcao,
          descricao: el.passoGeral.descricao,
          arvoreProduto: el.passoGeral.arvoreProduto,
          dtaInicio: this.ajustarFormatoData(el, 'dtaInicio'),
          dtaFim: this.ajustarFormatoData(el, 'dtaFim'),
        };
        return obj;
      });
    },
    usuarioPodeSolicitarEsseTipoAcao(acao) {
      return this.tiposAcaoSolicitaveis.includes(acao.nomeTipoAcao);
    },
  },
  mounted() {
    setTimeout(this.setAcoes, 500);
  },
};
</script>
<style>
.PlanejamentoContratoFormAcoesControleLista__ColunaGrande {
  min-width: 210px;
}
.PlanejamentoContratoFormAcoesControleLista__ColunaMedia {
  min-width: 120px;
}
</style>

import moment from 'moment';

export default {
  name: 'planejamentoContratoCalculoValorTotalAcaoMixin',
  data() {
    return {
    };
  },
  computed: {
    possuiPeriodoApuracaoUnicaVez() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('UNICA_VEZ') > -1;
    },
    possuiPeriodoApuracaoMensal() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('MENSAL') > -1;
    },
    possuiPeriodoApuracaoTrimestral() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('TRIMESTRAL') > -1;
    },
    possuiPeriodoApuracaoAnual() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('ANUAL') > -1;
    },
  },
  methods: {
    calcularValorTotal(isAcaoVigenciaTrimestral = false) {
      if (this.dtaInicio && this.dtaFim) {
        let valorTotal = 0;
        const periodoMensal = 1;
        const periodoTrimestral = 3;
        const periodoAnual = 12;

        if (this.coeficienteCalculoValorTotalAcao === 0) {
          return;
        }
        if (this.possuiPeriodoApuracaoUnicaVez) {
          valorTotal = this.coeficienteCalculoValorTotalAcao;
        }
        if (this.possuiPeriodoApuracaoMensal) {
          valorTotal += this.calcularValorTotalPorPeriodicidade(periodoMensal);
        }
        if (this.possuiPeriodoApuracaoTrimestral) {
          if (isAcaoVigenciaTrimestral) {
            this.passoApuracao.dtaInicio = this.dtaInicio
              ? moment(this.dtaInicio, 'YYYY-MM').startOf('month').format('YYYY-MM-DD') : null;
            this.passoApuracao.dtaFim = this.dtaFim
              ? moment(this.dtaFim, 'YYYY-MM').endOf('month').format('YYYY-MM-DD') : null;
          } else {
            this.passoApuracao.dtaInicio = this.dtaInicio;
            this.passoApuracao.dtaFim = this.dtaFim;
          }
          valorTotal += this.calcularValorTotalPorPeriodicidade(periodoTrimestral);
        }
        if (this.possuiPeriodoApuracaoAnual) {
          valorTotal += this.calcularValorTotalPorPeriodicidade(periodoAnual);
        }

        this.passoGeral.valor = valorTotal;
      }
    },
    calcularValorTotalPorPeriodicidade(periodo) {
      if (!this.passoApuracao.dtaInicio
        || !this.passoApuracao.dtaFim) {
        return 0;
      }
      const dtaInicio = this.moment(this.passoApuracao.dtaInicio, 'YYYY-MM-DD');
      const dtaFim = this.moment(this.passoApuracao.dtaFim, 'YYYY-MM-DD');
      const mesDiff = dtaFim.diff(dtaInicio, 'months') + 1;
      let quantidade = Math.floor(mesDiff / periodo);

      if (mesDiff % periodo !== 0) {
        quantidade += 1;
      }
      return quantidade * this.coeficienteCalculoValorTotalAcao;
    },
    coeficienteCalculoAlterado(valor) {
      this.coeficienteCalculoValorTotalAcao = valor;
      this.calcularValorTotal();
    },
  },
};

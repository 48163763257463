<template>
  <v-dialog v-model="dialog" :persistent="false" max-width="60%">
    <div>
    <v-row justify="end">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="registros"
          hide-default-footer
          :no-data-text="$t('message.nao_ha_conteudo')">
        </v-data-table>
      </v-col>
    </v-row>
  </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalProdutosKit',
  props: {
    notaDebito: {
      type: Object,
      default: () => ({}),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      valor: '',
      registros: [],
      headers: [
        { text: this.$tc('label.produto', 2), value: 'codNomeProduto', sortable: false },
      ],
    };
  },
  methods: {
    open(produtos) {
      console.log('produtos', produtos);
      this.registros = produtos;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>

<template>
  <div>
    <v-dialog v-model="exibeModalProduto" max-width="1200px" persistent>
      <v-row align="center" v-if="quantidadeDeCaracteresExcedida">
        <v-col cols="12" sm="12" class="mx-3">
          <v-alert
            type="warning"
            prominent
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t('label.quantidade_caracteres_excedida') }}
              </v-col>
              <v-col class="shrink">
                <v-btn color="accent" @click="resetarCampoPesquisa">{{ $t('label.ok') }}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <div class="card-title">
            {{ $tc('label.selecionar_produto', 2) }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row justify="start">
            <v-col cols="12" sm="12" class="mx-3">
              <v-text-field
                v-model="searchQuery"
                append-icon="search"
                :label="$t('label.pesquisar_modal_selecao_produto')"
                single-line
                clearable
                hide-details>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="mx-3 pt-0">
              <span class="orange--text" mt-5>{{ $tc('label.pesquisar_modal_selecao_produto_mensagem', 1) }}</span>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listaProdutos"
            v-model="selected"
            :key="tableModalProdutosKey"
            :show-select="!somenteLeitura"
            @toggle-select-all="realizarSelecaoTotal"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50],
            }">
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secundary"
            @click="close">{{ (somenteLeitura ) ? $t('label.voltar') : $t('label.cancelar') }}</v-btn>
          <v-btn
            color="primary"
            class="mr-3"
            v-if="!somenteLeitura && selected.length"
            @click="selecionarProdutos" >{{ $t('label.selecionar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { removerItensDuplicadosPorId } from '../../../../common/functions/helpers';

export default {
  name: 'PlanejamentoAcaoVerbaVariavelModalSelecaoProdutoMeta',
  components: {
  },
  props: {
    somenteLeitura: Boolean,
    meta: Object,
    configuracao: {
      type: Object,
      required: true,
    },
    edicao: false,
    categoriaSelecionada: Object,
    isTodosSelecionado: Boolean,
    origem: Object,
    acao: Object,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),

      exibeModalProduto: false,
      searchQuery: null,
      listaProdutos: [],
      selected: [],
      tableModalProdutosKey: 0,
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      timeout: 0,
      esperar: false,
      iniciarTodosSelecionados: false,
      tamanhoTotalProdutos: 0,
      headers: [
        { text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', sortable: false },
        { text: this.$tc('label.familia', 1), value: 'codNomeFamilia', sortable: false },
        { text: this.$tc('label.ibm', 1), value: 'desIbm', sortable: false },
        { text: this.$tc('label.cod_produto_com_digito', 1), value: 'codProdutoComDigito', sortable: false },
        { text: this.$tc('label.produto', 1), value: 'codNomeProduto', sortable: false },
        { text: this.$tc('label.referencia', 1), value: 'desModelo', sortable: false },
      ],
      quantidadeDeCaracteresExcedida: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.iniciarTodosSelecionados && this.exibeModalProduto) {
          this.filtrar();
        }
      },
      deep: true,
    },
    searchQuery() {
      if (this.searchQuery !== null) {
        const searchQueryArray = this.searchQuery.includes(',') ? this.searchQuery.split(',') : '';
        if (searchQueryArray.length > 1000) {
          this.quantidadeDeCaracteresExcedida = true;
        } else {
          this.quantidadeDeCaracteresExcedida = false;
          if (this.exibeModalProduto) {
            this.pagination.page = 1;
            if (this.timeout) {
              window.clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
              this.filtrar();
            }, 500);
          }
        }
      } else {
        this.quantidadeDeCaracteresExcedida = false;
        if (this.exibeModalProduto) {
          this.pagination.page = 1;
          if (this.timeout) {
            window.clearTimeout(this.timeout);
          }
          this.timeout = window.setTimeout(() => {
            this.filtrar();
          }, 500);
        }
      }
    },
  },
  methods: {
    open() {
      const buscaInicial = true;
      if (!this.meta.idsProdutos || !this.meta.idsProdutos.length) {
        this.iniciarTodosSelecionados = true;
      } else {
        const buscaCompleta = true;
        this.iniciarTodosSelecionados = false;
        this.buscaProdutos(buscaCompleta, buscaInicial);
      }
      this.filtrar();
      this.exibeModalProduto = true;
    },
    close() {
      this.exibeModalProduto = false;
      if (this.edicao) {
        this.$emit('AtualizarMeta', this.meta);
      }
      this.resetaModal();
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        const isSelecaoInicial = true;
        if (this.iniciarTodosSelecionados) {
          this.realizarSelecaoTotal(null, isSelecaoInicial);
          this.iniciarTodosSelecionados = false;
        } else if (this.searchQuery && this.searchQuery.length) {
          this.realizarSelecaoTotal(null, !isSelecaoInicial);
        }
        this.buscaProdutos();
      }, 1000);
    },
    buscaProdutos(buscaCompleta = false, buscaInicial = false) {
      const { idGrupoFornecedor, fornecedor } = this.origem;
      const params = {
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: this.pagination.sortDesc ? !this.pagination.sortDesc[0] : true,
        colunaOrdenacao: this.pagination.sortBy ? this.pagination.sortBy[0] : 'cod_nome_produto',
      };
      if (fornecedor) {
        params.idGrupoFornecedor = fornecedor.idGrupoFornecedor;
      } else {
        params.idGrupoFornecedor = idGrupoFornecedor;
      }
      if (this.searchQuery && this.searchQuery.length) {
        if (this.searchQuery.includes(',')) {
          const searchQueryArray = this.searchQuery.split(',');
          params.filtroArray = searchQueryArray;
        } else {
          params.autocomplete = this.searchQuery;
        }
      }
      if (this.categoriaSelecionada) {
        params.idsCategorias = this.categoriaSelecionada.idsCategoriasAgrupadas.toString();
      }
      if (this.meta.idsProdutos && this.somenteLeitura) {
        params.idsProdutos = this.meta.idsProdutos;
      }
      if (this.meta.idsProdutos && !this.iniciarTodosSelecionados && !this.somenteLeitura) {
        params.idsProdutosSelecionadosOrdenacao = this.meta.idsProdutos;
      }
      if (buscaCompleta) {
        params.numeroPagina = 1;
        params.tamanhoPagina = 999999;
      }

      return this.planejamentoContratoResource.buscarProdutosCartaParceria(params)
        .then((res) => {
          if (buscaCompleta) {
            this.todosProdutos = res.data.resposta;
            if (this.tamanhoTotalProdutos === 0) {
              this.tamanhoTotalProdutos = this.todosProdutos.length;
            }
            if (buscaInicial && this.meta.idsProdutos && this.meta.idsProdutos.length) {
              this.selected = this.todosProdutos.filter((p) => this.meta.idsProdutos.some((id) => id === p.id));
            }
          } else {
            this.totalPage = res.data.quantidadeRegistrosPagina;
            this.listaProdutos = res.data.resposta;
            this.tableModalProdutosKey += 1;
            this.pararEsperar();
          }
        });
    },
    realizarSelecaoTotal(e, isSelecaoInicial = false) {
      const buscaCompleta = true;
      this.buscaProdutos(buscaCompleta)
        .then(() => {
          if (isSelecaoInicial) {
            this.selected = [...this.todosProdutos];
          } else if (this.searchQuery && this.searchQuery.length) {
            if (e !== null) {
              if (e.value) {
                const produtosSelecionados = [...this.selected, ...this.todosProdutos];
                this.selected = [...removerItensDuplicadosPorId(produtosSelecionados)];
              } else {
                this.selected = this.selected.filter((p) => !this.todosProdutos.some((tp) => tp.id === p.id));
              }
            } else if (this.selected.length === this.tamanhoTotalProdutos) {
              this.selected = [];
            }
          } else if (e !== null && this.totalPage > this.pagination.itemsPerPage) {
            if (this.selected.length !== this.tamanhoTotalProdutos
              && this.selected.length !== (this.tamanhoTotalProdutos - this.pagination.itemsPerPage)) {
              this.selected = [...this.todosProdutos];
            } else {
              this.selected = [];
            }
          }
        });
    },
    selecionarProdutos() {
      let idsProdutosSelecionados = null;
      if (this.tamanhoTotalProdutos !== this.selected.length) {
        idsProdutosSelecionados = this.selected.map((p) => p.id);
      }
      this.meta.idsProdutos = idsProdutosSelecionados;
      if (idsProdutosSelecionados && idsProdutosSelecionados.length) {
        if (this.meta.isProdutoObrigatorio) {
          this.meta.idsProdutosObrigatorios = [...idsProdutosSelecionados];
        } else if (this.meta.isProdutoExcecao) {
          this.meta.idsProdutosExcecao = [...idsProdutosSelecionados];
        }
      } else {
        this.meta.idsProdutosObrigatorios = null;
        this.meta.idsProdutosExcecao = null;
      }
      this.acao.indAcaoAlterada = true;
      this.close();
    },
    resetarCampoPesquisa() {
      this.searchQuery = null;
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
    resetaModal() {
      this.selected = [];
      this.listaProdutos = [];
      this.iniciarTodosSelecionados = false;
      this.searchQuery = null;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.tamanhoTotalProdutos = 0;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
};
</script>

<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ mensagem }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            id="justificativa_obrigatoria"
            name="justificativa-obrigatoria"
            :label="`${$tc('label.justificativa', 1)} *`"
            v-model="justificativa"
            auto-grow
            autofocus
            rows="1"
            counter="250"
            :rules="[rules.required, rules.maxLength250]"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="confirmarCancelamento">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      showModal: false,
      justificativa: '',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        maxLength250: (v) => (v && v.length <= 250) || this.$t('message.limite_caracteres_excedido'),
      },
    };
  },
  props: {
    cancelarCategoria: Function,
    observacao: String,
    mensagem: String,
  },
  methods: {
    open() {
      this.justificativa = '';
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    confirmarCancelamento() {
      if (!this.$refs.form.validate()) return;
      this.cancelarCategoria(this.justificativa);
      this.showModal = false;
    },
  },
  mounted() {
  },
};
</script>

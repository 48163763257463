<template>
  <div class="PlanejamentoContratoForm_Rodape">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4" class="pt-0 pl-0">
          <v-stepper v-model="stepForm">
            <v-stepper-header>
              <div v-for="(passo, index) in sequenciaPassos"
                :key="index"
                :style="{'width': `${100/sequenciaPassos.length}%`}"
                class="PlanejamentoContratoForm_Rodape__Passo">
                <v-stepper-step
                  :complete="stepForm > (index + 1)"
                  :step="(index+1)">
                  {{passo}}
                </v-stepper-step>
                <v-divider
                  v-if="index < sequenciaPassos.length - 1"/>
              </div>
            </v-stepper-header>
          </v-stepper>
        </v-col>

        <planejamento-contrato-form-fluxo-aprovacao
          ref="componenteFluxo"
          :ultimo-passo="ultimoPasso"
          :permite-salvar="permiteSalvar"
          :contrato-edicao="contratoEdicao"
          :contrato-salvar="concluirContrato"
          :contrato-formatar="formataContratoParaEnvio"
          :contrato-validar="validaAntesDeSalvarOuFinalizar"
          :configuracao="configuracao"
          :edicao="edicao"
          :upload-evidencia-obrigatorio="uploadEvidenciaObrigatorio"
          :ind-tem-evidencia="indTemEvidencia"
          @PlanejamentoContratoFormFluxoAprovacao__podeSolicitarAprovacaoContrato
              ="emitirPodeSolicitarAprovacaoContrato"
          @PlanejamentoContratoFormFluxoAprovacao__temFluxoContrato="emitirTemFluxoContrato">
          <v-btn slot="acoes-padrao" @click="cancelar()"
            class="mr-3"
            v-show="indCancelar">
            {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="anterior()"
            class="mr-3"
            dark
            color="accent"
            v-show="exibeAnterior">
            {{ $t('label.anterior') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="proximo()"
            class="mr-3"
            dark
            color="primary"
            v-show="exibeProximo">
            {{ $tc('label.proximo', 1) }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="salvarRecarregar()"
            class="mr-3"
            dark
            color="primary"
            v-show="exibeSalvar"
            :disabled="!permiteSalvar">
            {{ $t('label.salvar') }}
          </v-btn>
        </planejamento-contrato-form-fluxo-aprovacao>
      </v-row>
    </v-container>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PlanejamentoContratoFormFluxoAprovacao from './PlanejamentoContratoFormFluxoAprovacao';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import { forceLoading, forceHideLoading } from '../../../common/functions/loading';

export default {
  props: {

    definicaoPassos: Object,

    alterarFinalizado: Boolean,
    copia: Boolean,
    novo: Boolean,
    edicao: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    indTemEvidencia: Boolean,
    indCancelar: Boolean,
    indSemVerba: Boolean,

    getComponentesPassos: Function,
    validaComponentePasso: Function,

    configuracao: {
      type: Object,
      required: true,
    },
  },
  components: {
    PlanejamentoContratoFormFluxoAprovacao,
    Confirm,
  },
  data() {
    return {
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      stepForm: 1,
      avancaPasso: () => {},
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    exibeSalvar() {
      if (this.somenteLeitura) {
        return false;
      }
      return this.ultimoPasso;
    },
    permiteSalvar() {
      return (
        !this.contratoEdicao.status
        || this.contratoEdicao.status === 'EM_CADASTRO'
      );
    },
    ultimoPasso() {
      const ultimoPasso = Object.keys(this.sequenciaPassos).length;
      return this.stepForm === ultimoPasso;
    },
    exibeAnterior() {
      return this.stepForm !== 1;
    },
    exibeProximo() {
      return !this.ultimoPasso;
    },
    sequenciaPassos() {
      return Object.values(this.passosHabilitados)
        .filter((passo) => passo.habilitado)
        .map((passo) => passo.label);
    },
    passosHabilitados() {
      return this.definicaoPassos.passosHabilitados(this, this.configuracao.objeto, this.contratoEdicao);
    },
    uploadEvidenciaObrigatorio() {
      return this.configuracao
        && this.configuracao.configuracao
        && this.configuracao.configuracao.passo1
        && this.configuracao.configuracao.passo1.uploadObrigatorio;
    },
  },
  methods: {
    carregaInformacoesFluxo(idContrato) {
      if (this.$refs.componenteFluxo) {
        this.$refs.componenteFluxo.carregaInformacoesFluxo(idContrato);
      }
    },
    emitirPodeSolicitarAprovacaoContrato(podeSolicitarAprovacaoContrato) {
      setTimeout(() => {
        this.$emit('PlanejamentoContratoFormRodape__podeSolicitarAprovacaoContrato',
          podeSolicitarAprovacaoContrato);
      }, 3E2);
    },
    emitirTemFluxoContrato(temFluxoContrato) {
      setTimeout(() => {
        this.$emit('PlanejamentoContratoFormRodape__temFluxoContrato', temFluxoContrato);
      }, 3E2);
    },
    validaAntesDeAvancar() {
      return this.validaComponentePasso();
    },
    abreUltimoPasso() {
      this.stepForm = Object.keys(this.sequenciaPassos).length;
      this.notificaNovoPasso();
    },
    proximo() {
      if (!this.validaAntesDeAvancar()) {
        return;
      }
      this.stepForm += 1;
      this.notificaNovoPasso();
      this.scrollToTop();
    },
    anterior() {
      this.stepForm -= 1;
      this.notificaNovoPasso();
      this.scrollToTop();
    },
    reduzirUmPasso() {
      this.stepForm -= 1;
    },
    notificaNovoPasso() {
      this.$emit('PlanejamentoContratoFormRodape__MudaPasso', this.stepForm);
    },
    cancelar() {
      if (this.somenteLeitura) {
        if (this.$route.params.from && this.$route.params.modalDashboard && this.$route.params.from === 'dashboard-inicial') {
          this.$router.push({ name: this.$route.params.from, params: { modalDashboard: this.$route.params.modalDashboard } });
        } else {
          this.$router.push({
            name: this.$route.params.from || 'planejamento-contrato',
          });
        }
        return;
      }

      this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_cancelar_edicao'),
          (ok) => {
            if (ok) {
              if (this.$route.params.from && this.$route.params.modalDashboard && this.$route.params.from === 'dashboard-inicial') {
                this.$router.push({ name: this.$route.params.from, params: { modalDashboard: this.$route.params.modalDashboard } });
              } else {
                this.$router.push({
                  name: this.$route.params.from || 'planejamento-contrato',
                });
              }
            }
          },
        );
    },
    validaAntesDeSalvarOuFinalizar(finalizacao) {
      if (!this.validaComponentePasso()) {
        return Promise.resolve(false);
      }

      const componentes = Object.values(this.getComponentesPassos())
        .filter((c) => c.validacaoAssincrona);

      if (componentes.length === 0) {
        return Promise.resolve(true);
      }

      return Promise.all(
        componentes.map((c) => c.validacaoAssincrona(finalizacao)),
      ).then((arrValidacao) => Promise.resolve(arrValidacao.reduce((a, b) => a && b)));
    },
    formataContratoParaEnvio() {
      const { geral, acoes } = this.getComponentesPassos();
      const passoGeral = geral.getObjetoFormatado();
      passoGeral.indSemVerba = this.indSemVerba;

      const passoComportamento = acoes.getObjetoFormatado();
      passoComportamento.acoes.forEach((el) => this.formataCodCampanha(el));
      let tipoPassoAcoes = 'CONTRATO_PADRAO_TRADELINKS';

      if (this.configuracao.isTipoContratoCartaParceria || this.configuracao.isTipoContratoSamsung
          || this.configuracao.isTipoContratoNivelFamilia || this.configuracao.isTipoContratoEpoca) {
        tipoPassoAcoes = 'CARTA_PARCERIA';
      }

      return {
        id: this.contratoEdicao.id,
        tipoPassoAcoes,
        passoGeral,
        passoComportamento,
      };
    },
    formataCodCampanha(el) {
      el.codCampanha = '';
      if (el.formaBonificacao === 'FIXO') {
        el.codCampanha = el.codCampanha
          .concat('VALOR_FIXO');
      } else {
        el.codCampanha = el.codCampanha
          .concat('%');
      }
      if (el.calculoBonificacao) {
        el.codCampanha = el.codCampanha.concat('-').concat(el.calculoBonificacao.toUpperCase());
      }

      if (el.baseCalculo === 'VALOR_BRUTO') {
        el.codCampanha = el.codCampanha
          .concat('-').concat('BRUTO');
      } else {
        el.codCampanha = el.codCampanha
          .concat('-').concat('NET');
      }
    },
    salvarRecarregar() {
      forceLoading();
      setTimeout(() => {
        this.salvar()
          .then((idContrato) => {
            forceHideLoading();
            if (idContrato !== null) {
              forceHideLoading();
              this.alternaRota(idContrato);
            } else {
              forceHideLoading();
            }
          })
          .catch((err) => {
            this.$error(this, err);
            forceHideLoading();
          });
      }, 1000);
    },
    concluirContrato() {
      return this.salvar(false, false);
    },
    salvar(finalizacao = false, exibeTooltip = true) {
      forceLoading();
      return this.validaAntesDeSalvarOuFinalizar(finalizacao).then((sucesso) => {
        if (!sucesso) {
          forceHideLoading();
          return null;
        }
        const contrato = this.formataContratoParaEnvio();
        if (!this.edicao) {
          return this.salvarNovo(contrato, finalizacao);
        }
        return this.atualizar(contrato, finalizacao, exibeTooltip);
      });
    },
    salvarNovo(contrato, finalizacao) {
      return this.planejamentoContratoResource.salvar(contrato)
        .then((res) => {
          if (!finalizacao) {
            this.$toast(this.$t('message.adicionado_confira_tabela'));
          }
          const idContrato = res.data;
          forceHideLoading();
          return idContrato;
        });
    },
    atualizar(contrato, finalizacao, exibeTooltip = true) {
      return this.planejamentoContratoResource.atualizar(contrato)
        .then((res) => {
          const idContrato = res.data;
          if (!finalizacao && exibeTooltip) {
            this.$toast(this.$t('message.atualizar_tabela'));
          }
          forceHideLoading();
          return idContrato;
        });
    },
    alternaRota(idContrato) {
      const routeEdicao = 'editarContrato';

      let { name } = this.$router.currentRoute;
      name = name === routeEdicao
        ? name.concat('_') : routeEdicao;

      this.$router.replace({
        name,
        params: { idContrato, passoFinal: true },
      });
    },
    exibeFinalizar() {
      if (this.contratoEdicao.id && this.ultimoPasso && !this.somenteLeitura) {
        const { passoGeral } = this.contratoEdicao;
        if (passoGeral.status !== 'APROVADO') {
          const idContrato = this.contratoEdicao.id;
          return this.workflowContratoResource.existeFluxoCadastrado({ idContrato })
            .then((res) => {
              const existeFluxo = res.data;
              return !existeFluxo;
            });
        }
        return false;
      }
      return false;
    },
    finalizar() {
      return this.validaAntesDeSalvarOuFinalizar(true).then((sucesso) => {
        if (!sucesso) {
          return;
        }
        this.$refs.confirmDialog
          .openWithParams(
            this.$t('title.atencao'),
            this.$t('message.deseja_finalizar_contrato'),
            (ok) => {
              if (ok) {
                const contrato = this.formataContratoParaEnvio();
                this.planejamentoContratoResource.finalizar(contrato)
                  .then(() => {
                    this.$router.push({ name: 'planejamento-contrato' });
                    this.$toast(this.$t('message.contrato_finalizado'));
                  })
                  .catch((err) => {
                    this.$error(this, err);
                  });
              }
            },
          );
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    const { passoFinal, carregaPassoAcoes } = this.$route.params;
    if (passoFinal === true || carregaPassoAcoes === true) {
      setTimeout(() => this.abreUltimoPasso());
    }
  },
};
</script>
<style>
  .PlanejamentoContratoForm_Rodape {
    height: 56px;
    background-color: #EEEEEE;
    display: flex;

    position: fixed;
    z-index: 4;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
    width: 100%;
  }
  .PlanejamentoContratoForm_Rodape .v-stepper {
    background: none;
    border: none;
    box-shadow: none;
  }
  .PlanejamentoContratoForm_Rodape .v-stepper__header,
  .PlanejamentoContratoForm_Rodape .v-stepper__step {
    height: 56px;
  }
  .PlanejamentoContratoForm_Rodape__Passo {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>

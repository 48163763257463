import { copyObject } from '../../../../common/functions/helpers';

const limpaCamposProduto = (copia, metadadosProduto) => {
  const { mapaEntidades } = metadadosProduto;
  const dependencias = Object.keys(mapaEntidades)
    .filter((c) => c !== 'unidadenegocio');

  const { passoGeral } = copia;
  passoGeral.produtos = [];

  dependencias.forEach((dependencia) => {
    passoGeral.mapaExtensoes[dependencia] = null;
    passoGeral[dependencia] = null;
  });
};

export default function realizaCopiaAcao(acao, metadadosProduto) {
  const copia = copyObject(acao);
  const idTipoAcao = copia.passoGeral.idAcaoTipo;

  delete copia.id;
  delete copia.passoGeral.descricao;

  if (copia.passoComportamento && copia.passoComportamento.metas
    && copia.passoComportamento.metas.length) {
    copia.passoComportamento.metas.forEach((m) => {
      delete m.id;
      delete m.idAcao;
    });
  }

  limpaCamposProduto(copia, metadadosProduto);

  return {
    ...copia,
    idTipoAcao,
  };
}

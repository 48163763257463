<template>
  <div>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="400"
        transition="scale-transition"
        offset-x
        left>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn v-on="{ ...tooltip, ...menu }" class="ma-0" icon :color="corIcone">
                <v-icon>{{ icone }}</v-icon>
              </v-btn>
            </template>
            <span>{{ textoAcao }}</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title class="headline">{{ titleDialog }}</v-card-title>
          <v-card-text>
            <span v-html="message" />
            <v-container fluid grid-list-md class="pl-0">
              <v-row>
                <v-col cols="12" sm="9">
                  <v-combobox
                    id="categoria"
                    :key="comboKey"
                    v-model="categoriasCopiaSelecionadas"
                    :items="listaCategorias"
                    autocomplete="off"
                    :item-disabled="'atingiuMaximo'"
                    item-text="codNome"
                    item-value="id"
                    clearable
                    chips
                    multiple
                    deletable-chips
                    :rules="[rules.requiredLista]"
                    :label="$tc('label.categoria', 1)">
                    <template slot="item" slot-scope="{ item }">
                      <v-list-item-content v-bind:class="{ 'primary--text': item.atingiuMaximo}">
                        <span>{{ item.codNome }}</span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="primary"
                          v-if="item.atingiuMaximo">
                          playlist_add_check
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="disagree">{{ $t('label.cancelar') }}</v-btn>
            <v-btn color="primary darken-1" text @click.native="agree">{{ $t('label.adicionar') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'PopoverConfirmacao',
  data: () => ({
    menu: false,
    categoriasCopiaSelecionadas: [],
    comboKey: 0,

    rules: {
      requiredLista: (value) => (!!value && value.length > 0) || '',
    },
  }),
  watch: {
    listaCategorias: {
      handler() {
        this.comboKey += 1;
      },
      deep: true,
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: 'info',
    },
    textoAcao: {
      type: String,
      default: '',
    },
    categoria: {
      type: Object,
      default: '',
    },
    listaCategorias: {
      type: Array,
      default: [],
    },
  },
  computed: {
    titleDialog() {
      return (!this.title || !this.title.length) ? this.$t('title.atencao') : this.title;
    },
  },
  methods: {
    inputLabel(item) {
      if (item.label) {
        return item.label;
      }
      return this.$tc(`label.${item.campo}`, 1);
    },
    disagree() {
      this.close();
      this.$emit('disagree');
      this.categoriasCopiaSelecionadas = [];
    },
    agree() {
      if (!this.$refs.form.validate()) return;
      this.finish();
    },
    finish() {
      this.close();
      this.$emit('agree', this.categoria, this.categoriasCopiaSelecionadas);
      this.categoriasCopiaSelecionadas = [];
    },
    close() {
      this.menu = false;
    },
  },
};
</script>

<template>
  <v-card style="min-height:168px; height: 100%;">
    <v-card-title style="word-break: break-word; width: 97%;">
      {{ $tc('label.intervalo_meta', 1) }} {{ subtitulo }}
    </v-card-title>
    <v-card-text>
      <v-container fluid px-0 py-0>
        <v-row>
          <v-col cols="12" >
            <v-data-table
              :headers="headers"
              :items="metas"
              hide-default-footer
              disable-pagination
              :key="tableKey"
              :items-per-page="0"
              :no-data-text="$t('message.nenhum_intervalo')"
              style="overflow:hidden">
              <template v-slot:item="{ item }">
                <tr>
                  <td v-if="comMetaDePartida">
                    <v-checkbox
                      v-model="item.indMetaDePartida"
                      primary
                      :disabled="acaoAprovada"
                      hide-details
                      class="mx-5 mt-0"
                      @click.native="acao.indAcaoAlterada = true, indMetaDePartidaAlterado(item)"
                    ></v-checkbox>
                  </td>
                  <td class="text-center">
                    <span v-text="`${$t(`configuracao.CALCULO_APURACAO.${item.calculoApuracao}`)}`"></span>
                  </td>
                  <td class="text-center">
                    <span v-text="exibirMetaDe(item)"></span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.vlrMetaAte" v-text="exibirMetaAte(item)"></span>
                  </td>
                  <td class="text-center">
                    <span v-text="exibirPagamento(item.vlrBonificacao)"></span>
                  </td>
                  <td  class="text-center">
                    <v-btn
                      icon
                      class="mx-2"
                      @click.stop="abrirModalSelecaoProduto(item, true)"
                    >
                      <v-tooltip top v-if="item.idsProdutos && item.idsProdutos.length">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">category</v-icon>
                        </template>
                        <span
                        v-text="$t('message.quantidade_produtos_selecionados', {text: item.idsProdutos.length})">
                        </span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                          add_box
                          </v-icon>
                        </template>
                        <span v-text="$t('message.todos_produtos')"></span>
                      </v-tooltip>
                    </v-btn>
                  </td>
                  <td class="justify-center px-0" style="text-align: center;" v-if="!somenteLeitura">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mx-0" v-on="on" @click="editar(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('label.editar') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mx-0" v-on="on" @click="openConfirmRemocao(item)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $tc('label.remover') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-btn absolute
              small
              right
              top
              icon
              @click="incluirIntervalo"
              color="primary"
              v-if="!somenteLeitura">
              <v-tooltip bottom left>
                <span>{{$t('label.novo')}}</span>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">add</v-icon>
                </template>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary darken-1" text @click.native="fecharModalMetaIntervalo">{{ $t('label.ok') }}</v-btn>
    </v-card-actions>

    <v-dialog v-model="showModal" eager max-width="60%">
      <v-form ref="formModal" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ $tc('label.intervalo_meta', 1) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-row>
                    <v-col cols="10">
                      <v-autocomplete
                      clearable
                      v-model="meta.calculoApuracao"
                      :label="`${$tc('label.calculo_apuracao', 1)} *`"
                      item-text="text"
                      item-value="value"
                      :items="acaoCalculosApuracao()"
                      @change="acao.indAcaoAlterada = true, valorCalculoApuracaoAlterado"
                    >
                    </v-autocomplete>
                  </v-col>
                    <v-col cols="2" style="display: flex; align-items: center; flex-direction: row-reverse; margin-left: -15px;">
                      <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" size="23px" class="ml-2">info</v-icon>
                      </template>
                      <template v-slot:default>
                        <span v-html="`${$t('message.ajuda.planejamento_contrato.configuracao_apuracao_cumulativa')}`"></span>
                      </template>
                    </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <input-money
                    v-if="calculoValor"
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :value="meta.vlrMetaDe"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    class="v-text-field"
                    :rules="[rules.required]"
                    :required="true"/>
                  <v-text-field
                    v-else-if="calculoVolume"
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    :suffix="sigla"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-else-if="calculoPercentual"
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    suffix="%"
                    :rules="[rules.required, v => rules.valorMaximo(v, 100)]"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    :rules="[rules.required, rules.valorInteiro]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <input-money
                    v-if="calculoValor"
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    @change="acao.indAcaoAlterada = true"
                    :value="meta.vlrMetaAte"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    :rules="[rules.metaAteMenorValor]"
                    class="v-text-field"/>
                  <v-text-field
                    v-else-if="calculoVolume"
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    :suffix="sigla"
                    :rules="[rules.metaAteMenorVolume]"
                  ></v-text-field>
                  <v-text-field
                    v-else-if="calculoPercentual"
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    suffix="%"
                    :rules="[rules.metaAteMenorVolume, v => rules.valorMaximo(v, 100)]"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    :rules="[rules.metaAteMenorVariavel, rules.valorInteiro]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    :label="`${$t('label.verba', 1)} *`"
                    v-model="meta.vlrBonificacao"
                    @change="acao.indAcaoAlterada = true"
                    suffix="%"
                    :disabled="somenteLeitura || !meta.calculoApuracao"
                    type="Number"
                    :min="0"
                    :rules="[rules.requiredValorPositivo, v => rules.valorMaximo(v, 100)]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  <span class="grey--text caption">
                    {{$tc('label.produto')}}
                  </span>
                  <v-btn
                    icon
                    class="mx-2"
                    @click.stop="abrirModalSelecaoProduto(meta)"
                  >
                    <v-tooltip top v-if="meta.idsProdutos && meta.idsProdutos.length">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">category</v-icon>
                      </template>
                      <span
                      v-text="$t('message.quantidade_produtos_selecionados', {text: meta.idsProdutos.length})">
                      </span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                        add_box
                        </v-icon>
                      </template>
                      <span v-text="$t('message.todos_produtos')"></span>
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="somenteLeitura" text @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
            <v-btn v-if="!somenteLeitura" text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
            <v-btn v-if="!somenteLeitura" color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-card>

        <confirm
          ref="confirmRemocao"
          :message="$t('message.deseja_remover_meta')"
          :persistent="true"
          @agree="remover">
        </confirm>
        <planejamento-contrato-modal-selecao-produto-meta
          ref="modalSelecaoProdutoMeta"
          :meta="meta"
          :edicao="edicao"
          :somente-leitura="somenteLeitura"
          :configuracao="configuracao"
          :categoria-selecionada="categoria"
          :origem="dadosOrigem"
          :acao="acao"
          @AtualizarMeta="atualizarMeta"
          />
      </v-form>
    </v-dialog>
  </v-card>
</template>
<script>
import InputMoney from '../../../../shared-components/inputs/InputMoney';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';
import {
  convertToMoney,
  convertToPercent,
  convertToNumberFormat,
  numberBetweenValues,
} from '../../../../common/functions/numeric';
import { copyObject, convertToIntegerFormat } from '../../../../common/functions/helpers';
import PlanejamentoContratoModalSelecaoProdutoMeta from './PlanejamentoContratoModalSelecaoProdutoMeta';

export default {
  data() {
    return {
      showModal: false,
      tableKey: 1,
      rules: {
        required: (value) => (!!value || value === 0 || value === '0') || this.$t('message.campo_obrigatorio'),
        requiredValorPositivo: (value) => (!!value && value > 0) || this.$t('message.campo_obrigatorio'),
        metaAteMenorValor: () => (parseFloat(this.meta.vlrMetaAte) > parseFloat(this.meta.vlrMetaDe)
          || this.meta.vlrMetaAte === 0) || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVolume: () => (!this.meta.vlrMetaAte
          || parseFloat(this.meta.vlrMetaAte) > parseFloat(this.meta.vlrMetaDe)
          || this.meta.vlrMetaAte === '0') || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVariavel: () => (!this.meta.vlrMetaAte
          || parseFloat(this.meta.vlrMetaAte) > parseFloat(this.meta.vlrMetaDe)
          || this.meta.vlrMetaAte === '0') || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteInfinito: () => (this.meta.vlrMetaAte === '' || this.meta.vlrMetaAte > 0) || this.$t('errors.meta_ate_maior_meta_de'),
        valorMaximo: (value, max) => value <= max || this.$t('errors.valor.maximo', { max }),
        valorInteiro: (value) => ((!!value || value === 0) && (Number.isInteger(Number(value)))) || this.$t('errors.valor.inteiro'),
      },
      edicao: false,
      meta: {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
      },
      headers: [
        {
          text: this.$t('label.calculo_apuracao'), value: 'calculo_apuracao', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'vlr_bonificacao', sortable: false, align: 'center',
        },
        {
          text: this.$tc('label.produto'), value: 'produto', sortable: false, align: 'center',
        },
      ],
      calculoVolume: false,
      calculoValor: false,
      calculoPercentual: false,
      objetoPassoGeral: null,
      dadosOrigem: null,
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    metas: {
      type: Array,
      default: () => ([]),
    },
    sigla: {
      type: String,
      default: '',
    },
    verbaPercentual: {
      type: Boolean,
      default: false,
    },
    subtitulo: {
      type: String,
      default: '',
    },
    acao: Object,
    categoria: Object,
    configuracao: Object,
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  watch: {
    metas: {
      handler() {
        this.ajustarValorAcao();
      },
      deep: true,
    },
  },
  components: {
    InputMoney,
    Confirm,
    PlanejamentoContratoModalSelecaoProdutoMeta,
  },
  computed: {
    acaoAprovada() {
      return this.acao.status === 'APROVADO';
    },
    comMetaDePartida() {
      const { meta } = this.acao.configuracao.configuracao.passo5.apuracao;
      return meta === 'CUMULATIVA_COM_META_PARTIDA';
    },
  },
  methods: {
    convertToMoney,
    convertToPercent,
    convertToNumberFormat,
    numberBetweenValues,
    convertToIntegerFormat,
    incluirIntervalo() {
      this.edicao = false;
      this.meta = {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
        idsProdutos: null,
        calculoApuracao: null,
      };
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
      this.meta = {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
        idsProdutos: null,
        calculoApuracao: null,
      };
    },
    ajustarValorAcao() {
      if (!this.verbaPercentual && this.metas && this.metas.length) {
        this.$emit('PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte',
          this.pegarMaiorValorDaMeta());
      }
    },
    fecharModalMetaIntervalo() {
      const { meta } = this.acao.configuracao.configuracao.passo5.apuracao;
      if (!this.validarMetaPartida() && this.metas.length && meta === 'CUMULATIVA_COM_META_PARTIDA') {
        this.$toast(this.$t('errors.meta.sem_meta_partida'));
        return;
      }
      this.$emit('PlanejamentoContratoFormMetaIntervalo__fecharModalMetaIntervalo');
    },
    pegarMaiorValorDaMeta() {
      return this.metas.map((m) => m.vlrBonificacao)
        .reduce((a, b) => Math.max(a, b));
    },
    atualizarMeta(meta) {
      this.meta = copyObject(meta);
      this.salvar();
    },
    salvar() {
      if (this.meta.vlrMetaDe) {
        this.meta.vlrMetaDe = Number(this.meta.vlrMetaDe);
      }
      if (this.meta.vlrMetaAte) {
        this.meta.vlrMetaAte = Number(this.meta.vlrMetaAte);
      }
      if (this.meta.vlrBonificacao) {
        this.meta.vlrBonificacao = Number(this.meta.vlrBonificacao);
      }
      if (!this.$refs.formModal.validate() || this.intervaloEmConflito()
      || this.valoresNegativos()) return;
      if (this.edicao) {
        this.metas.forEach((meta, index) => {
          if (index === this.meta.index) {
            meta.vlrMetaDe = this.meta.vlrMetaDe;
            meta.vlrMetaAte = this.meta.vlrMetaAte;
            meta.vlrBonificacao = this.meta.vlrBonificacao;
            meta.idsProdutos = this.meta.idsProdutos;
            meta.calculoApuracao = this.meta.calculoApuracao;
          }
        });
      } else {
        this.metas.push(this.meta);
      }
      this.ordenarLista();
      this.tableKey += 1;
      this.showModal = false;
      this.meta = {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
        idsProdutos: null,
        calculoApuracao: null,
      };
    },
    ordenarLista() {
      function compare(a, b) {
        if (a.calculoApuracao < b.calculoApuracao) return -1;
        if (a.calculoApuracao > b.calculoApuracao) return 1;
        return 0;
      }
      this.metas.sort((a, b) => a.vlrMetaDe - b.vlrMetaDe).sort(compare);
    },
    exibirMetaDe(item) {
      if (item.calculoApuracao) {
        if (item.calculoApuracao === 'SELLIN_VOLUME' || item.calculoApuracao === 'SELLOUT_VOLUME') {
          return convertToNumberFormat(item.vlrMetaDe);
        }
        if (item.calculoApuracao === 'SELLIN_PERCENTUAL' || item.calculoApuracao === 'SELLOUT_PERCENTUAL') {
          return convertToPercent(item.vlrMetaDe);
        }
        return convertToMoney(item.vlrMetaDe);
      }
      return convertToNumberFormat(item.vlrMetaDe, {
        precision: 0,
      });
    },
    exibirMetaAte(item) {
      if (item.calculoApuracao) {
        if (item.calculoApuracao === 'SELLIN_VOLUME' || item.calculoApuracao === 'SELLOUT_VOLUME') {
          return convertToNumberFormat(item.vlrMetaAte);
        }
        if (item.calculoApuracao === 'SELLIN_PERCENTUAL' || item.calculoApuracao === 'SELLOUT_PERCENTUAL') {
          return convertToPercent(item.vlrMetaAte);
        }
        return convertToMoney(item.vlrMetaAte);
      }
      return convertToNumberFormat(item.vlrMetaAte, {
        precision: 0,
      });
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
    editar(item) {
      this.edicao = true;
      this.meta = copyObject(item);
      this.meta.index = this.metas.indexOf(item);
      this.valorCalculoApuracaoAlterado();
      this.showModal = true;
    },
    openConfirmRemocao(item) {
      this.meta = item;
      this.$refs.confirmRemocao.open();
    },
    remover() {
      this.metas.splice(this.metas.indexOf(this.meta), 1);
      this.tableKey += 1;
    },
    intervaloEmConflito() {
      for (let i = 0; i < this.metas.length; i += 1) {
        const intervalo = this.metas[i];
        if (this.meta.index !== i
          && this.meta.calculoApuracao === intervalo.calculoApuracao
          && (JSON.stringify(this.meta.idsProdutos) === JSON.stringify(intervalo.idsProdutos)
            || (!this.meta.idsProdutos && !intervalo.idsProdutos))
          && this.validarConflito(intervalo.vlrMetaDe, intervalo.vlrMetaAte)) {
          this.$toast(this.$t('errors.meta.intervalos.conflito'));
          return true;
        }
      }
      return false;
    },
    validarConflito(vlrMetaDe, vlrMetaAte) {
      if (!this.meta.vlrMetaAte) {
        return this.validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte);
      }
      return this.validarConflitoEntreValores(vlrMetaDe, vlrMetaAte);
    },
    validarConflitoEntreValores(vlrMetaDe, vlrMetaAte) {
      return this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
          || (this.numberBetweenValues(this.meta.vlrMetaAte, vlrMetaDe, vlrMetaAte))
          || ((vlrMetaAte !== 0) && this.numberBetweenValues(vlrMetaAte, this.meta.vlrMetaDe, this.meta.vlrMetaAte))
          || this.numberBetweenValues(vlrMetaDe, this.meta.vlrMetaDe,
            this.meta.vlrMetaAte);
    },
    validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte) {
      return !vlrMetaAte
        || this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
        || this.meta.vlrMetaDe < vlrMetaDe;
    },
    valoresNegativos() {
      if (this.meta.vlrMetaDe < 0 || this.meta.vlrMetaAte < 0 || this.meta.vlrBonificacao < 0) {
        this.$toast(this.$t('errors.meta.negativa'));
        return true;
      }
      return false;
    },
    acaoCalculosApuracao() {
      const { calculosApuracao } = this.acao.configuracao.configuracao.passo5.apuracao;
      return calculosApuracao.map((calculoApuracao) => ({
        text: `${this.$t(`configuracao.CALCULO_APURACAO.${calculoApuracao}`)}`,
        value: calculoApuracao,
      }));
    },
    valorCalculoApuracaoAlterado() {
      if (this.meta.calculoApuracao) {
        if (this.meta.calculoApuracao === 'SELLIN_VOLUME' || this.meta.calculoApuracao === 'SELLOUT_VOLUME') {
          this.calculoVolume = true;
          this.calculoValor = false;
          this.calculoPercentual = false;
        } else if (this.meta.calculoApuracao === 'SELLIN_PERCENTUAL' || this.meta.calculoApuracao === 'SELLOUT_PERCENTUAL') {
          this.calculoVolume = false;
          this.calculoValor = false;
          this.calculoPercentual = true;
        } else if (this.meta.calculoApuracao === 'QUANTIDADE') {
          this.calculoVolume = true;
          this.calculoValor = false;
          this.calculoPercentual = false;
        } else {
          this.calculoVolume = false;
          this.calculoValor = true;
          this.calculoPercentual = false;
        }
        if (this.meta.metas && this.meta.metas.length) {
          this.meta.calculoApuracaoAlterado = true;
        }
      }
    },
    abrirModalSelecaoProduto(meta, edicao = false) {
      this.meta = copyObject(meta);
      if (edicao) {
        this.meta.index = this.metas.indexOf(meta);
        this.edicao = true;
      }
      this.montaObjetoContrato();
      setTimeout(() => {
        this.$refs.modalSelecaoProdutoMeta.open();
      }, 350);
    },
    montaObjetoContrato() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      this.dadosOrigem = {
        idFornecedor: this.objetoPassoGeral.fornecedor ? this.objetoPassoGeral.fornecedor.id : null,
        idGrupoFornecedor: this.objetoPassoGeral.grupoFornecedor
          ? this.objetoPassoGeral.grupoFornecedor.id : this.objetoPassoGeral.fornecedor.idGrupoFornecedor,
        fornecedor: this.objetoPassoGeral.fornecedor,
      };
    },
    validarMetaPartida() {
      return this.metas.some((meta) => meta.indMetaDePartida === true);
    },
    indMetaDePartidaAlterado(item) {
      const index = this.metas.indexOf(item);
      this.metas.forEach((meta, i) => { if (index !== i) meta.indMetaDePartida = false; });
    },
  },
  mounted() {
    if (this.metas && this.metas.length) {
      this.ajustarValorAcao();
    }
    if (!this.somenteLeitura) {
      this.headers.push(
        {
          text: '', value: '', sortable: false, align: 'center',
        },
      );
    }
    if (this.comMetaDePartida) {
      this.headers.unshift(
        {
          text: this.$t('label.meta_de_partida'), value: 'meta_de_partida', sortable: false, align: 'left',
        },
      );
    }
  },
};
</script>

<template>
  <div>
    <v-dialog v-model="dialogApuracoesAgrupadas" v-if="dialogApuracoesAgrupadas" max-width="1200px" style="max-height: 400px">
      <v-card class="mr-1 mt-1 mb-1">
        <v-card-title>
          <h3 class="headline mb-0">{{ $t('title.impacto_apuracoes_agrupadas',
            {
              tipoAcao: acaoAgrupadora.tipoAcao,
              tipoAcaoAgrupadora: acaoAgrupadora.tipoAcaoAgrupadora,
            }
          ) }}</h3>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="apuracoesAgrupadas"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 20, 30],
            }">
            <template v-slot:item.cod_apuracao="{ item }">
              {{ item.codApuracao != 0 ? item.codApuracao : $t('label.nova') }}
            </template>
            <template v-slot:item.status="{ item }">
              {{ $tc(`status_entidade.${item.status.toLowerCase()}`, 1) }}
            </template>
            <template v-slot:item.data_inicio="{ item }">
              {{ item.dataInicio }}
            </template>
            <template v-slot:item.data_fim="{ item }">
              {{ item.dataFim }}
            </template>
            <template v-slot:item.atualiza_apuracao="{ item }">
              {{ item.atualizaApuracao ? $t('label.impacto_apuracoes_agrupadas_atualizacao') : $t('label.impacto_apuracoes_agrupadas_inclusao')}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="close">{{ $t('label.nao') }}</v-btn>
          <v-btn @click.native="solicitarAprovacao" color="primary">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'PlanejamentoContratoFormAcoesApuracoesAgrupadas',
  components: {
  },
  props: {
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      dialogApuracoesAgrupadas: false,
      acaoAgrupadora: {},
      acaoSelecionada: null,
      apuracoesAgrupadas: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'cod_apuracao', width: '10%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.status_apuracao', 1), value: 'status', width: '20%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.inicio_apuracao', 1), value: 'data_inicio', width: '15%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.fim_apuracao', 1), value: 'data_fim', width: '15%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.descricao_impacto', 1), value: 'atualiza_apuracao', align: 'center', sortable: false,
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.acaoSelecionada) {
          this.validarAcaoAgrupadoraEApuracoes(this.acaoSelecionada);
        }
      },
      deep: true,
    },
  },
  methods: {
    open() {
      this.dialogApuracoesAgrupadas = true;
    },
    close() {
      this.dialogApuracoesAgrupadas = false;
    },
    validarAcaoAgrupadoraEApuracoes(acao) {
      this.acaoSelecionada = acao;
      const params = {
        idAcao: acao.id,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };
      this.workflowAcaoResource.validarAcaoAgrupadoraEApuracoes(params)
        .then((res) => {
          if (res.data
            && res.data.resposta[0]
            && res.data.resposta[0].dataValidaParaSolicitarAprovacao) {
            if (res.data.resposta.length > 1) {
              const [acaoAgrupadora] = res.data.resposta[0];
              this.acaoAgrupadora = acaoAgrupadora;
              this.totalPage = res.data.quantidadeRegistrosPagina;
              this.apuracoesAgrupadas = res.data.resposta.slice(1, res.data.length);
              this.open();
            } else {
              this.solicitarAprovacao();
            }
          } else {
            this.$emit('ERRO_CONFLITO_ACAO_AGRUPADORA', res.data.resposta[0].nomeAcaoAgrupadora);
          }
        })
        .catch((err) => this.$error(this, err));
    },
    solicitarAprovacao() {
      this.$emit('SOLICITAR_APROVACAO');
      this.close();
    },
  },
};
</script>

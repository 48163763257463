<style>
  .PlanejamentoContratoForm {
    margin-bottom: 100px;
  }
  .PlanejamentoContratoForm .card-title {
    font-size: 18px;
    font-weight: 500;
  }
  .PlanejamentoContratoForm .card-subtitle {
    font-size: 16px;
    font-weight: 400;
  }
</style>
<template>
  <div class="PlanejamentoContratoForm" v-if="carregouEstrutura">

    <component v-for="(p, index) in componentesPassos" :key="index"
      :is="p.componente"
      :ref="p.nome"
      v-show="exibePasso(p.componente)"
      :tipo-contrato="tipoContrato"
      :configuracao="configuracao"
      :contrato-edicao="contratoEdicao"
      :edicao="edicao"
      :novo-contrato="novo"
      :alterar-finalizado="alterarFinalizado"
      :copia="copia"
      :novo="novo"
      :somente-leitura="naoPodeEditar"
      :pode-solicitar-aprovacao-contrato="podeSolicitarAprovacaoContrato"
      :tem-fluxo-contrato="temFluxoContrato"
      :get-objeto-outro-passo="getObjetoOutroPasso"
      @PlanejamentoContratoForm__AtualizaIndTemEvidencia="atualizaIndTemEvidencia"
      @PlanejamentoContratoFormAcoes__carregarContrato="carregarContrato"
      @PlanejamentoContratoFormAcaoes__atualizarStatusContrato="atualizarStatusContrato"
      @PlanejamentoContratoFormCampos__atualizarDatasAcoes="atualizarDatasAcoes"
      @PLANEJAMENTO_CONTRATO_PASSO_ANTERIOR="() => $refs.rodapeAcoes.anterior()"
      @PlanejamentoContratoFormAcoes__setValorContrato="setValorContrato"
      @PlanejamentoContratoFormAcoesControle__indSemVerba="setIndSemVerba">
    </component>

    <div class="PlanejamentoContratoForm_Acoes" v-if="carregouEstrutura">
      <planejamento-contrato-form-rodape
        ref="rodapeAcoes"
        :definicao-passos="definicaoPassos"
        :alterarFinalizado="alterarFinalizado"
        :copia="copia"
        :edicao="edicao"
        :novo="novo"
        :somente-leitura="naoPodeEditar"
        :contrato-edicao="contratoEdicao"
        :configuracao="configuracao"
        :get-componentes-passos="getComponentesPassos"
        :valida-componente-passo="validaComponentePasso"
        :ind-tem-evidencia="indTemEvidencia"
        :indCancelar="!idContratoFechamento"
        :ind-sem-verba="indSemVerba"
        @PlanejamentoContratoFormRodape__MudaPasso="validaAlteracaoOrigem"
        @PlanejamentoContratoFormRodape__podeSolicitarAprovacaoContrato="preencherPodeSolicitarAprovacaoContrato"
        @PlanejamentoContratoFormRodape__temFluxoContrato="preencherTemFluxoContrato"
        />
    </div>

    <confirm
      ref="origemAlteradaDialog"
      :message="mensagemOrigemAlterada"
      :persistent="true"
      max-width="32em"
      @agree="removerCategoriasPorOrigemAlterada"
      @disagree="reduzirUmPasso">
    </confirm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import definicaoPassos from '@/spa/planejamento-contrato/form/planejamentoDefinicaoPassos';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { forceLoading, forceHideLoading } from '../../../common/functions/loading';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoContratoFormRodape from './PlanejamentoContratoFormRodape';
import ContratoConfiguracao from '../ContratoConfiguracao';

export default {
  components: {
    ...definicaoPassos.componentes,
    PlanejamentoContratoFormRodape,
    Confirm,
  },
  computed: {
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    tituloTipoContrato() {
      return this.tipoContrato != null ? this.tipoContrato.nome : '';
    },
    subtituloTipoContrato() {
      return this.tipoContrato != null ? this.tipoContrato.descricao : '';
    },
    carregouEstrutura() {
      return this.tipoContrato != null && this.configuracao != null;
    },
    contratoAprovado() {
      return this.statusContrato === 'APROVADO';
    },
    naoPodeEditar() {
      return this.somenteLeitura || this.contratoAprovado
        || !this.canEdit;
    },
    renderizaFormApuracao() {
      return !this.novo && this.objetoPassoGeral != null && this.contratoEdicao.id != null;
    },
    componentesPassos() {
      return Object.keys(this.passosHabilitados)
        .filter((nome) => this.passosHabilitados[nome].habilitado)
        .map((nome) => ({
          nome,
          componente: this.passosHabilitados[nome].componente,
        }));
    },
    passosHabilitados() {
      return this.definicaoPassos.passosHabilitados(this, this.configuracao.configuracao, this.contratoEdicao);
    },
  },
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    somenteLeitura: Boolean,
    idContratoFechamento: Number,
  },
  data() {
    return {
      tipoContratoResource: this.$api.tipoContrato(this.$resource),
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),
      tipoAcaoResources: this.$api.tipoAcao(this.$resource),
      configuracao: null,
      podeSolicitarAprovacaoContrato: false,
      temFluxoContrato: false,
      tipoContrato: null,
      contratoEdicao: {},
      objetoPassoGeral: null,
      camposDeProdutoDoContrato: null,
      statusContrato: null,

      passoAberto: 1,
      passoSelecionado: null,
      definicaoPassos,
      indTemEvidencia: false,
      mensagemOrigemAlterada: null,
      indSemVerba: false,
    };
  },
  methods: {
    setIndSemVerba(value) {
      this.indSemVerba = value;
    },
    setValorContrato(valor) {
      this.componentesPassos.filter((c) => this.$refs[c.nome][0].setValorContrato)
        .forEach((c) => this.$refs[c.nome][0].setValorContrato(valor));
    },
    preencherPodeSolicitarAprovacaoContrato(podeSolicitarAprovacaoContrato) {
      this.podeSolicitarAprovacaoContrato = podeSolicitarAprovacaoContrato;
    },
    preencherTemFluxoContrato(temFluxoContrato) {
      this.temFluxoContrato = temFluxoContrato;
    },
    exibePasso(componente) {
      return this.componentesPassos
        .map((p) => p.componente)
        .indexOf(componente) + 1 === this.passoAberto;
    },
    mudaPasso() {
      const passo = this.passoSelecionado;
      if (passo > this.passoAberto) {
        this.avancaPasso(passo);
      }
      this.setarExibeDicaPassoComportamento(passo);
      this.passoAberto = passo;
    },
    reduzirUmPasso() {
      this.$refs.rodapeAcoes.reduzirUmPasso();
    },
    validaAlteracaoOrigem(passo) {
      this.passoSelecionado = passo;
      const proximoPasso = Object.keys(this.passosHabilitados)[passo - 1];
      let alterouOrigem = false;
      if (proximoPasso === 'acoes' && (this.configuracao.isTipoContratoCartaParceria
        || this.configuracao.isTipoContratoSamsung || this.configuracao.isTipoContratoNivelFamilia || this.configuracao.isTipoContratoEpoca)) {
        alterouOrigem = this.$refs[proximoPasso][0].objetoPassoGeral != null
          && this.$refs[proximoPasso][0].teveOrigemAlterada();
      }
      if (passo > this.passoAberto
        && alterouOrigem) {
        const novaOrigem = this.$refs[proximoPasso][0].getNovaOrigem();
        this.mensagemOrigemAlterada = this.$t('message.contrato_origem_alterada', { tipoContrato: this.tipoContrato.nome, nomeGrupoFornecedor: novaOrigem.nomExtensao });
        this.$refs.origemAlteradaDialog.open();
      } else {
        this.mudaPasso();
      }
    },
    avancaPasso(passo) {
      const ref = Object.keys(this.passosHabilitados)[passo - 1];
      this.$refs[ref][0].abrePasso();
    },
    removerCategoriasPorOrigemAlterada() {
      const passoAcoes = Object.keys(this.passosHabilitados)[this.passoAberto];
      this.$refs[passoAcoes][0].removerCategoriasPorOrigemAlterada();
      this.mudaPasso();
    },
    atualizarDatasAcoes(dataInicio, dataFim) {
      const passoAcoes = Object.keys(this.passosHabilitados)[this.passoAberto];
      this.$refs[passoAcoes][0].atualizarDatasAcoes(dataInicio, dataFim);
    },
    carregarSeUsuarioPossuirAcesso(idContrato) {
      forceLoading();
      this.planejamentoContratoListagemResource.verificarSeUsuarioPossuiAcesso({ idContrato })
        .then((res) => {
          if (res.data) {
            this.carregarContrato(idContrato);
          } else {
            forceHideLoading();
            this.$router.push({ name: 'dashboard-inicial' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregarContrato(idContrato) {
      this.idContrato = idContrato;

      this.planejamentoContratoListagemResource.buscarContrato({ idContrato })
        .then((res) => {
          this.contratoEdicao = { ...res.data };
          this.statusContrato = this.contratoEdicao.passoGeral.status;
          return this.contratoEdicao.passoGeral.idContratoTipo;
        }).then((idContratoTipo) => {
          Promise.all([
            this.carregarTipoContrato(idContratoTipo),
            this.carregarConfiguracao(idContratoTipo),
          ]).then(() => {
            if (this.$refs.geral[0]) {
              setTimeout(() => {
                this.$refs.geral[0].montaObjetoContrato();
              });
            }
            if (this.$refs.acoes[0]) {
              setTimeout(() => {
                this.$refs.acoes[0].acoesCarregadas();
              });
            }
            setTimeout(() => this.$refs.rodapeAcoes.carregaInformacoesFluxo(idContrato));
            forceHideLoading();
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idTipoContrato) {
      const { idContrato } = this;

      const promise = !this.novo
        ? this.configuracaoResource.buscarConfigSnapshot({ idContrato })
        : this.configuracaoResource.buscarConfigVigente({ idTipoContrato });

      return promise.then((res) => {
        this.configuracao = new ContratoConfiguracao(res.data);

        this.tipoAcaoResources.buscarAtivosDeContrato().then((response) => {
          const mapaIdTipoAcaoAtivo = response.data.map((tipoAcao) => tipoAcao.id);
          const tiposAcaoAtivas = this.configuracao.tiposAcao.filter((tipoAcao) => mapaIdTipoAcaoAtivo.includes(tipoAcao.idAcaoTipo));
          this.configuracao.configuracao.passo1.tiposAcao = tiposAcaoAtivas;
        }, (err) => {
          this.$error(this, err);
        });
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarTipoContrato(idTipoContrato) {
      return this.tipoContratoResource.buscarTipoContratoComConfiguracao({ idTipoContrato })
        .then((res) => {
          this.tipoContrato = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    validaComponentePasso() {
      const ref = Object.keys(this.passosHabilitados)[this.passoAberto - 1];
      const componente = this.$refs[ref][0];
      if (componente && componente.valida) {
        return componente.valida();
      }
      return true;
    },
    getObjetoOutroPasso(passo) {
      const componente = this.$refs[passo][0];
      if (componente.getObjetoPasso) {
        return componente.getObjetoPasso();
      }
      return null;
    },
    getComponentesPassos() {
      const componentes = [];
      this.componentesPassos.map((c) => c.nome).forEach((ref) => {
        const [referencia] = this.$refs[ref];
        componentes[ref] = referencia;
      });
      return componentes;
    },
    atualizarStatusContrato(statusContrato) {
      this.statusContrato = statusContrato;
      this.$refs.geral[0].setStatusContrato(statusContrato);
    },
    setarExibeDicaPassoComportamento(passo) {
      if (this.passoAberto > passo) {
        const objPassoAberto = Object.keys(this.passosHabilitados)[this.passoAberto - 1];
        if (objPassoAberto === 'acoes') {
          this.$refs[objPassoAberto][0].setarExibeDica(false);
        }
      }
    },
    atualizaIndTemEvidencia(indicador) {
      this.indTemEvidencia = indicador;
    },
  },
  mounted() {
    if (this.novo) {
      const { idTipoContrato } = this.$route.params;
      this.carregarTipoContrato(idTipoContrato);
      this.carregarConfiguracao(idTipoContrato);
    } else {
      const idContrato = this.$route.params.idContrato ? this.$route.params.idContrato : this.idContratoFechamento;
      // this.carregarContrato(idContrato);
      this.carregarSeUsuarioPossuirAcesso(idContrato);
    }
  },
};
</script>

<style>
.PlanejamentoContratoFormAcoesConfiguracao .container {
  padding: 16px;
}
.PlanejamentoContratoFormAcoesConfiguracao div.v-card {
  min-height: 168px;
}
</style>
<template>
  <v-container fluid grid-list-md
    class="PlanejamentoContratoFormAcoesConfiguracao">
    <v-row>
      <v-col cols="12">
        <div class="title-float accent--text">
          {{$tc('label.configuracao', 1)}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card style="height: 100%;">
          <v-card-title>
            {{$tc('label.apuracao', 1)}}
          </v-card-title>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col v-if="!vigenciaPorPeriodo" cols="12" md="6">
                <metadados-campo-dinamico-data
                  :metadados="definicaoDtaInicio"
                  v-model="dtaInicio"
                  :somente-leitura="somenteLeitura || vigenciaPredeterminada"
                  :objeto-container="passoApuracao"
                  :tipo-selecao="isAcaoVigenciaTrimestral ? 'month' : 'date'"
                  :is-vigencia-trimestral="isAcaoVigenciaTrimestral"
                  :is-data-inicio="true"
                  @input="dataAlterada"/>
              </v-col>
              <v-col v-if="!vigenciaPorPeriodo" cols="12" md="6">
                <metadados-campo-dinamico-data
                  :metadados="definicaoDtaFim"
                  v-model="dtaFim"
                  :somente-leitura="somenteLeitura || vigenciaPredeterminada"
                  :objeto-container="passoApuracao"
                  :tipo-selecao="isAcaoVigenciaTrimestral ? 'month' : 'date'"
                  :is-vigencia-trimestral="isAcaoVigenciaTrimestral"
                  :is-data-fim="true"
                  @input="dataAlterada"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  id="periodo_apuracao"
                  class="custom-autocomplete"
                  v-model="passoApuracao.periodosApuracao"
                  clearable
                  chips
                  multiple
                  deletable-chips
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :item-disabled="desabilitarPeriodo"
                  :items="periodosApuracaoPermitidos"
                  :label="`${$tc('label.periodo_apuracao',1)} *`"
                  item-text="nome"
                  item-value="valor"
                  :disabled="somenteLeitura"
                  :rules="[rules.requiredAny]"
                  @change="calcularValorTotal">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="metaAutomatica && comMeta && temCalculosApuracoes">
                <v-autocomplete
                  class="custom-autocomplete"
                  v-model="passoApuracao.calculoApuracao"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="calculosApuracoesPermitidos"
                  :label="`${$tc('label.calculo_apuracao',1)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura"
                  @change="calculoApuracaoAlterado">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="metaAutomatica && comMeta && calculoVolume">
                <v-autocomplete
                  id="unidade_medida"
                  name="unidade_medida"
                  v-model="passoApuracao.unidadeMedida"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="unidadesMedidasPermitidas"
                  :label="`${$tc('label.unidade_medida', 1)} *`"
                  item-text="nomUnidade"
                  item-value="id"
                  :disabled="somenteLeitura"
                  :rules="[rules.required]"
                  @input="unidadeSelecionada">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="metaAutomatica && comMeta && !metaIntervalo && !passoGeral.indAcaoAgrupadora">
                <input-money
                  v-if="calculoValor && !unidadeQuantidade"
                  :label="$tc('label.meta', 1)"
                  v-model="passoApuracao.meta"
                  :disabled="somenteLeitura"
                  class="v-text-field"/>
                <v-text-field
                  v-else-if="calculoVolume && !unidadeQuantidade"
                  :label="$tc('label.meta', 1)"
                  v-model="passoApuracao.meta"
                  :disabled="somenteLeitura"
                  type="Number"
                  min="0"
                  :suffix="sigla"
                ></v-text-field>
                <v-text-field
                  v-else-if="calculoPercentual && !unidadeQuantidade"
                  :label="$tc('label.meta', 1)"
                  v-model="passoApuracao.meta"
                  :disabled="somenteLeitura"
                  type="Number"
                  min="0"
                  suffix="%"
                ></v-text-field>
                <v-text-field
                  v-else
                  :label="$tc('label.meta', 1)"
                  v-model="passoApuracao.meta"
                  :disabled="somenteLeitura"
                  type="Number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="metaAutomatica && metaIntervalo">
                <v-autocomplete
                  class="custom-autocomplete"
                  v-model="passoApuracao.formaBonificacao"
                  clearable
                  :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                  :items="formasBonificacaoPermitidas"
                  :label="`${$tc('label.forma_bonificacao',2)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.required]"
                  @change="formaBonificacaoSelecionada"
                  :disabled="somenteLeitura">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="metaIntervalo && !passoGeral.indAcaoAgrupadora">
        <planejamento-acao-form-apuracao-intervalo
          :somenteLeitura="somenteLeitura"
          :calculo-volume="calculoVolume"
          :calculo-valor="calculoValor"
          :calculo-percentual="calculoPercentual"
          :unidade-quantidade="unidadeQuantidade"
          :destacar-meta="destacarMeta"
          :verbaPercentual="verbaPercentual"
          :sigla="sigla"
          :metas="passoApuracao.metas"
          @retirarDestaque="() => destacarMeta = false"
          @PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte
              ="alterarCoeficienteCalculoValorTotalAcao"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="exibeExcecoes">
        <v-card>
          <v-card-title>
            {{$tc('label.excecao', 2)}}
          </v-card-title>
          <v-container>
            <metadados-container-layout
              v-if="parametrosAplicados"
              :metadados-entidade="metadadosParametrizado"
              :objeto="passoApuracao"
              :layout-class="layoutClass"
              :input-layout="inputLayout"
              :campos-formulario="camposFormulario"
              :ordenacao-campos="camposExcecao"
              :explode-hierarquia="true"
              :somente-leitura="somenteLeitura"
              ref="containerExcecao">
            </metadados-container-layout>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="exibeSessaoPagamentos">
        <v-card>
          <v-card-title>
            {{$tc('label.recebimento', 1)}}
          </v-card-title>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" md="6" v-if="metaAutomatica && !metaIntervalo">
                <v-autocomplete
                  id="verba"
                  class="custom-autocomplete"
                  v-model="passoApuracao.formaBonificacao"
                  clearable
                  :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                  :items="formasBonificacaoPermitidas"
                  :label="`${$tc('label.forma_bonificacao',2)} *`"
                  item-text="nome"
                  item-value="valor"
                  @change="formaBonificacaoSelecionada"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura || formasBonificacaoPermitidas.length === 1">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="(valorAcaoCalculadoAutomaticamente || metaNaoAplica) && !metaIntervalo && !passoGeral.indAcaoAgrupadora">
                <v-text-field
                  v-if="verbaPercentual"
                  id="valor_percentual"
                  :label="$tc('label.valor', 1)"
                  v-model="passoApuracao.valorBonificacao"
                  suffix="%"
                  :disabled="somenteLeitura"
                  :rules="[rules.valorMaiorZero]"
                  type="Number"
                ></v-text-field>
                <input-money
                  v-if="!verbaPercentual"
                  id="valor_fixo"
                  :label="$tc('label.valor', 1)"
                  v-model="passoApuracao.valorBonificacao"
                  :disabled="somenteLeitura || indValorManualMetaNAFixo"
                  :rules="rules.requiredValor(passoApuracao.valorBonificacao, metaNaoAplica, metaPorQuantidade, verbaFixa)"
                  @input="alterarCoeficienteCalculoValorTotalAcao(passoApuracao.valorBonificacao)"
                  class="v-text-field"/>
              </v-col>
              <v-col cols="12" md="6" v-if="metaAutomatica && temCalculosBonificacoesPermitidos && !verbaFixa">
                <v-autocomplete
                  id="calculo_bonificacao"
                  class="custom-autocomplete"
                  v-model="passoApuracao.calculoBonificacao"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="calculosBonificacoesPermitidos"
                  :label="`${$tc('label.calculo_bonificacao',1)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura || calculosBonificacoesPermitidos.length === 1">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="metaAutomatica && temBasesCalculoPermitidas && !metaNAFixa">
                <v-autocomplete
                  id="base_calculo"
                  class="custom-autocomplete"
                  v-model="passoApuracao.baseCalculo"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="basesCalculoPermitidas"
                  :label="`${$tc('label.base_calculo',2)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura || basesCalculoPermitidas.length === 1"
                  @input="baseSelecionada">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="tiposPagamentoPermitidos.length">
                <v-autocomplete
                  id="tipo_pagamento"
                  class="custom-autocomplete"
                  v-model="passoApuracao.tipoPagamento"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="tiposPagamentoPermitidos"
                  :label="`${$tc('label.tipo_pagamento',2)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura || tiposPagamentoPermitidos.length === 1">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="exibeDescontos">
        <v-card>
          <v-card-title>
            {{$tc('label.desconto', 2)}}
          </v-card-title>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" md="6"
                v-for="(m, index) in descontosFiltrados" :key="index">
                <v-checkbox
                  v-model="passoApuracao.descontos"
                  :label="$tc(`label.${m.toLowerCase()}`, 1)"
                  :value="m"
                  :disabled="somenteLeitura" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <confirm ref="confirm" :message="dialog.message" :persistent="true" @agree="dialog.agree" @disagree="dialog.disagree"></confirm>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import MetadadosContainerLayout from '../../../../shared-components/metadados/MetadadosContainerLayout';
import MetadadosCampoDinamicoData from '../../../../shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import PlanejamentoAcaoFormApuracaoIntervalo from '../../../planejamento-acao/form/PlanejamentoAcaoFormApuracaoIntervalo';
import PlanejamentoContratoFormAcoesApuracaoDefinicao from './PlanejamentoContratoFormAcoesApuracaoDefinicao';
import planejamentoContratoCalculoValorTotalAcaoMixin from './planejamentoContratoCalculoValorTotalAcaoMixin';
import InputMoney from '../../../../shared-components/inputs/InputMoney';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    somenteLeitura: Boolean,
    passoApuracao: Object,
    passoGeralContrato: Object,
    edicao: Boolean,
    novo: Boolean,
    configuracaoAcao: Object,
    configuracaoContrato: {
      type: Object,
      default: () => {},
    },
    passoGeral: {
      type: Object,
      required: true,
    },
  },
  components: {
    MetadadosContainerLayout,
    InputMoney,
    PlanejamentoAcaoFormApuracaoIntervalo,
    Confirm,
    MetadadosCampoDinamicoData,
  },
  mixins: [
    PlanejamentoContratoFormAcoesApuracaoDefinicao,
    planejamentoContratoCalculoValorTotalAcaoMixin,
  ],
  data() {
    return {
      rules: {
        valorMaiorZero: () => (!!this.passoApuracao.valorBonificacao
          && parseFloat(this.passoApuracao.valorBonificacao) > 0)
          || this.$t('errors.valor.maior_zero'),
      },
      dtaInicio: null,
      dtaFim: null,
      definicaoDtaInicio: {
        labelCampo: 'data_inicio',
        nomCampoId: 'dtaInicio',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
      definicaoDtaFim: {
        labelCampo: 'data_fim',
        nomCampoId: 'dtaFim',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
      coeficienteCalculoValorTotalAcao: 0,
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    vigenciaEhAbertaOuHerdada() {
      const { tipoVigencia } = this.configuracaoContrato;
      if (!tipoVigencia) {
        return false;
      }
      return ['VIGENCIA_ABERTA', 'HERDAR_PERIODO_ACAO']
        .includes(tipoVigencia);
    },
    vigenciaPorPeriodo() {
      const { tipoVigencia } = this.configuracaoContrato;
      if (!tipoVigencia) {
        return false;
      }
      return ['VIGENCIA_PERIODO']
        .includes(tipoVigencia);
    },
    vigenciaPredeterminada() {
      const { tipoVigencia } = this.configuracaoContrato;
      if (!tipoVigencia) {
        return false;
      }
      return ['VIGENCIA_PREDETERMINADA']
        .includes(tipoVigencia);
    },
    comMeta() {
      return this.configuracaoAcao
        && this.configuracaoAcao.templateCalculo !== ''
        && this.configuracaoAcao.templateCalculo !== 'META_N/A';
    },
    valorAcaoTemplateValorTotalAcao() {
      const { configuracao } = this.configuracaoAcao;
      return configuracao.passo3.template === 'VALOR_TOTAL_ACAO';
    },
    metaNaoAplica() {
      return this.configuracaoAcao.templateCalculo === 'META_N/A';
    },
    metaPorQuantidade() {
      return this.configuracaoAcao
        && this.configuracaoAcao.templateCalculo === 'META_POR_QUANTIDADE';
    },
    indValorManualMetaNAFixo() {
      return !!(!this.valorAcaoCalculadoAutomaticamente
        && this.metaNAFixa && this.passoGeral.valor);
    },
    exibeSessaoPagamentos() {
      return (this.metaAutomatica
        && (this.temCalculosBonificacoesPermitidos || this.temBasesCalculoPermitidas
        || !this.metaInterval))
        || this.tiposPagamentoPermitidos.length
        || ((this.valorAcaoCalculadoAutomaticamente || this.metaNaoAplica) && !this.metaIntervalo);
    },
    valorAcaoCalculadoAutomaticamente() {
      return this.configuracaoAcao.valorAcaoCalculadoAutomaticamente;
    },
    verbaPercentual() {
      return this.passoApuracao.formaBonificacao === 'PERCENTUAL';
    },
    verbaFixa() {
      if (this.passoApuracao.formaBonificacao
        && this.passoApuracao.formaBonificacao === 'FIXO') {
        return true;
      }
      return false;
    },
    metaNAFixa() {
      return this.metaNaoAplica && this.verbaFixa;
    },
    camposExcecao() {
      const { excecaoCampos } = this.configuracaoAcao;
      return excecaoCampos;
    },
    descontosFiltrados() {
      const { descontos, devolucoesHabilitadas } = this.configuracaoAcao;
      if (this.passoApuracao.baseCalculo
        && this.passoApuracao.baseCalculo === 'VALOR_LIQUIDO'
        && devolucoesHabilitadas) {
        return ['DEVOLUCOES'];
      }
      return descontos;
    },
    exibeDescontos() {
      const { descontos, devolucoesHabilitadas } = this.configuracaoAcao;
      return this.metaAutomatica && this.passoApuracao.baseCalculo
        && ((this.passoApuracao.baseCalculo === 'VALOR_BRUTO'
        && descontos && descontos.length)
          || (this.passoApuracao.baseCalculo === 'VALOR_LIQUIDO'
            && devolucoesHabilitadas));
    },
    exibeExcecoes() {
      return this.camposExcecao.length > 0;
    },
    metaIntervalo() {
      return this.configuracaoAcao.meta === 'INTERVALO';
    },
    metaAutomatica() {
      return this.configuracaoAcao.calculoAutomatico;
    },
    isAcaoVigenciaTrimestral() {
      return this.configuracaoAcao.indVigenciaTrimestral;
    },
    calculoVolume() {
      if (this.passoApuracao.calculoApuracao
        && (this.passoApuracao.calculoApuracao === 'SELLIN_VOLUME'
          || this.passoApuracao.calculoApuracao === 'SELLOUT_VOLUME')) {
        return true;
      }
      return false;
    },
    calculoValor() {
      return this.passoApuracao && this.passoApuracao.calculoApuracao
        && (this.passoApuracao.calculoApuracao === 'SELLIN_VALOR'
          || this.passoApuracao.calculoApuracao === 'SELLOUT_VALOR');
    },
    calculoPercentual() {
      return this.passoApuracao && this.passoApuracao.calculoApuracao
        && (this.passoApuracao.calculoApuracao === 'SELLIN_PERCENTUAL');
    },
    unidadeQuantidade() {
      return this.metaPorQuantidade;
    },
    valorAcao() {
      return this.passoGeral.valor;
    },
  },
  watch: {
    indValorManualMetaNAFixo(val) {
      if (val) {
        this.passoApuracao.valorBonificacao = this.passoGeral.valor;
      }
    },
    valorAcao(val) {
      if (this.indValorManualMetaNAFixo) {
        this.passoApuracao.valorBonificacao = val;
      } else if (this.valorAcaoTemplateValorTotalAcao) {
        this.calcularValorTotal();
      }
    },
  },
  methods: {
    dataAlterada() {
      if (this.dtaInicio && this.dtaFim) {
        this.calcularValorTotal(this.isAcaoVigenciaTrimestral);
        this.passoApuracao.dtaInicio = this.dtaInicio;
        this.passoApuracao.dtaFim = this.dtaFim;
      }
    },
    desabilitarPeriodo(item) {
      if (this.passoApuracao.periodosApuracao && !!this.passoApuracao.periodosApuracao.length) {
        if (this.passoApuracao.periodosApuracao.includes('UNICA_VEZ')) {
          return item.valor !== 'UNICA_VEZ';
        } if (!this.passoApuracao.periodosApuracao.includes('UNICA_VEZ')) {
          return item.valor === 'UNICA_VEZ';
        }
      }
      return false;
    },
    alterarCoeficienteCalculoValorTotalAcao(valor) {
      if (this.valorAcaoTemplateValorTotalAcao) {
        this.passoGeral.coeficienteValorTotal = valor;
        this.coeficienteCalculoAlterado(valor);
      }
    },
    baseSelecionada() {
      this.passoApuracao.descontos = [];
    },
    unidadeSelecionada(item) {
      const unidade = this.unidadesMedidasPermitidas.filter((u) => u.id === item);
      if (unidade && unidade.length) {
        this.sigla = unidade[0].sigla;
      }
    },
    calculoApuracaoAlterado() {
      this.passoApuracao.unidadeMedida = null;
      this.passoApuracao.meta = 0;
    },
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracaoAcao
        .aplicaParametrizacao(this.getAcaoMetadado);
    },
    formataExcecoes(objetoFormatado) {
      if (this.camposExcecao.length) {
        const campoProduto = {
          ...this.$refs.container.getValoresCamposPadrao(),
        };
        objetoFormatado.idProduto = campoProduto.idProduto;

        objetoFormatado.mapaExcecoes = {
          ...this.$refs.container.getValoresDependencias(),
        };
      }
    },
    getObjetoFormatado() {
      const {
        periodosApuracao,
        baseCalculo,
        formaBonificacao,
        tipoPagamento,
        calculoBonificacao,
        valorBonificacao,
        calculoApuracao,
        unidadeMedida,
        meta,
        metas,
        descontos,
      } = this.passoApuracao;

      const objetoFormatado = {
        periodosApuracao,
        baseCalculo,
        formaBonificacao,
        tipoPagamento,
        calculoBonificacao,
        valorBonificacao,
        calculoApuracao,
        unidadeMedida,
        meta,
        metas,
        descontos,
      };

      this.formataVigencia(objetoFormatado);

      if (this.exibeExcecoes) {
        const campoProduto = {
          ...this.$refs.containerExcecao.getValoresCamposPadrao(),
        };
        objetoFormatado.idProduto = campoProduto.idProduto;
        objetoFormatado.mapaExcecoes = {
          ...this.$refs.containerExcecao.getValoresDependencias(),
        };
      }

      return objetoFormatado;
    },
    formataVigencia(objetoFormatado) {
      if (this.isAcaoVigenciaTrimestral) {
        objetoFormatado.dtaInicio = moment(this.passoApuracao.dtaInicio, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        objetoFormatado.dtaFim = moment(this.passoApuracao.dtaFim, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else {
        objetoFormatado.dtaInicio = this.passoApuracao.dtaInicio;
        objetoFormatado.dtaFim = this.passoApuracao.dtaFim;
      }
    },
    validarMetas() {
      if (this.metaIntervalo && !this.passoApuracao.metas.length) {
        this.$toast(this.$t('message.intervalo_meta_obrigatorio'));
        return false;
      }
      return true;
    },
    validarDatas() {
      const dataInicial = moment(this.passoApuracao.dtaInicio);
      const dataFinal = moment(this.passoApuracao.dtaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    finalizarSelecaoFormaBonificacao(destacarMeta) {
      if (this.calculosBonificacoesPermitidos.length === 1 && !this.verbaFixa) {
        this.passoApuracao.calculoBonificacao = this.calculosBonificacoesPermitidos[0].valor;
      } else {
        this.passoApuracao.calculoBonificacao = null;
      }
      if (this.basesCalculoPermitidas.length === 1 && !this.metaNAFixa) {
        this.passoApuracao.baseCalculo = this.basesCalculoPermitidas[0].valor;
      } else {
        this.passoApuracao.baseCalculo = null;
      }

      this.formaBonificacaoAnterior = this.passoApuracao.formaBonificacao;
      this.destacarMeta = destacarMeta;
    },
    retornarAFormaBonificacaoAnterior() {
      this.passoApuracao.formaBonificacao = this.formaBonificacaoAnterior;
    },
    formaBonificacaoSelecionada() {
      if (!this.passoApuracao.metas
        || !this.passoApuracao.metas.length) {
        this.finalizarSelecaoFormaBonificacao(false);
      } else {
        this.dialog.message = this.$tc('message.alterar_forma_bonificacao_redefinir_meta', 2);
        this.dialog.agree = () => this.finalizarSelecaoFormaBonificacao(true);
        this.dialog.disagree = this.retornarAFormaBonificacaoAnterior;
        setTimeout(() => this.$refs.confirm.open());
      }
    },
    preencherAcaoNova() {
      if (this.periodosApuracaoPermitidos.length === 1) {
        this.passoApuracao.periodosApuracao = [this.periodosApuracaoPermitidos[0].valor];
      }
      if (this.calculosApuracoesPermitidos.length === 1) {
        this.passoApuracao.calculoApuracao = this.calculosApuracoesPermitidos[0].valor;
      }
      if (this.unidadesMedidasPermitidas.length === 1) {
        this.passoApuracao.unidadeMedida = this.unidadesMedidasPermitidas[0].id;
      }
      if (this.formasBonificacaoPermitidas.length === 1) {
        this.passoApuracao.formaBonificacao = this.formasBonificacaoPermitidas[0].valor;
      }
      if (this.calculosBonificacoesPermitidos.length === 1) {
        this.passoApuracao.calculoBonificacao = this.calculosBonificacoesPermitidos[0].valor;
      }
      if (this.basesCalculoPermitidas.length === 1) {
        this.passoApuracao.baseCalculo = this.basesCalculoPermitidas[0].valor;
      }
      if (this.tiposPagamentoPermitidos.length === 1) {
        this.passoApuracao.tipoPagamento = this.tiposPagamentoPermitidos[0].valor;
      }
      if (this.vigenciaPredeterminada) {
        this.passoApuracao.dtaInicio = this.passoGeralContrato.dtaVigenciaInicio;
        this.passoApuracao.dtaFim = this.passoGeralContrato.dtaVigenciaFim;
      }
    },
    formatarDataVigenciaTrimestral() {
      if (this.isAcaoVigenciaTrimestral) {
        this.dtaInicio = this.passoApuracao.dtaInicio
          ? moment(this.passoApuracao.dtaInicio, 'YYYY-MM-DD').format('YYYY-MM') : null;
        this.dtaFim = this.passoApuracao.dtaFim
          ? moment(this.passoApuracao.dtaFim, 'YYYY-MM-DD').format('YYYY-MM') : null;
      } else {
        this.dtaInicio = this.passoApuracao.dtaInicio;
        this.dtaFim = this.passoApuracao.dtaFim;
      }
    },
  },
  mounted() {
    this.buscarTiposPagamento().then(() => {
      if (this.novo) {
        this.preencherAcaoNova();
      }
      this.formatarDataVigenciaTrimestral();
      setTimeout(() => {
        this.aplicaParametrizacoes();
        this.parametrosAplicados = true;
      });
    });
  },
};
</script>

import { mapGetters } from 'vuex';
import { copyObject } from '../../../../common/functions/helpers';
import { generateComputed } from '../../../../common/functions/roles-computed-generator';

export default {
  props: {
    configuracao: {
      type: Object,
      default: () => {},
    },
    contrato: {
      type: Object,
      default: () => {},
    },
    contratoEdicao: {
      type: Object,
      default: () => {},
    },
    tiposAcaoContrato: {
      type: Array,
      default: () => [],
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: Boolean,
    edicao: Boolean,
    novo: Boolean,
    podeSolicitarAprovacaoContrato: Boolean,
    temFluxoContrato: Boolean,
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      msgRemocaoAcao: this.$t('message.remover_acao'),
      msgExecutarAnalise: this.$t('message.deseja_executar_analise_acao'),
      mensagemCancelar: this.$tc('message.deseja_cancelar_categoria', 1),
      mensagemAviso: '',
      msgFluxoSemAprovador: '',
      funcCancelar: () => ({}),
      ehSolicitante: false,
      filtro: '',
      passoCliente: {},
      modalLista: false,
      idCategoriaFluxo: null,
      categoriaSelecionada: null,
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
      'getUsuario',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),

    herdarPeriodoAcao() {
      const { tipoVigencia } = this.configuracao;
      const herdarPeriodo = ['HERDAR_PERIODO_ACAO'].includes(tipoVigencia);

      return herdarPeriodo;
    },
    idContrato() {
      const { id } = this.contratoEdicao;
      return id || null;
    },
    contratoEmCadastroOuAprovado() {
      return this.contratoEdicao && this.contratoEdicao.passoGeral
        && ['EM_CADASTRO', 'APROVADO'].includes(this.contratoEdicao.passoGeral.status);
    },
    contratoEmCadastro() {
      return this.novoContrato || (this.contratoEdicao && this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status === 'EM_CADASTRO');
    },
    podeCopiar() {
      return this.contratoEmCadastro && this.canEdit;
    },
  },
  methods: {
    permiteRemoverAcao(acao) {
      return acao && !acao.id;
    },
    permiteEditarAcao(acao) {
      return this.acaoEmCadastro(acao)
        && this.contratoEmCadastroOuAprovado
        && !this.acaoAgrupadora(acao);
    },
    permiteSolicitarAprovacao(acao) {
      return this.acaoEmCadastro(acao)
        && !this.podeSolicitarAprovacaoContrato
        && !!this.contrato.id
        && !this.acaoAgrupadora(acao);
    },
    permiteEditar(acao) {
      return this.acaoEmCadastro(acao)
        && !this.acaoAgrupadora(acao);
    },
    verificarSolicitante(idContrato) {
      this.workflowAcaoResource.solicitante({ idContrato })
        .then((response) => {
          if (response.data.usuarioHabilitado) {
            this.ehSolicitante = true;
          }
        });
    },
    contratoAguardandoAprovacao(contrato) {
      return (contrato && contrato.passoGeral
        && contrato.passoGeral.status === 'AGUARDANDO_APROVACAO');
    },
    podeExcluir(contrato) {
      return this.contratoEmCadastro && !contrato.id;
    },
    contratoEmAnalise(contrato) {
      return (contrato && contrato.passoGeral
        && acao.contrato.status === 'EM_ANALISE' && this.isUsuarioCriacao(contrato));
    },
    isUsuarioCriacao(contrato) {
      return (contrato && contrato.passoGeral
        && contrato.passoComportamento.idUsuarioCriacao === this.usuarioLogado.id);
    },
    filtrar(filtro) {
      this.filtro = filtro || '';
    },
    copiaCategoria(categoria, categoriasCopiaSelecionadas) {
      const categoriaModelo = copyObject(this.listaCategoriasCopia[categoria.index]);
      const listaNovasCategorias = [];

      categoriasCopiaSelecionadas.forEach((cat) => {
        const novaCategoria = copyObject(categoriaModelo);
        delete novaCategoria.index;
        novaCategoria.id = cat.id;
        novaCategoria.idExterno = cat.idExterno;
        novaCategoria.nomeCategoria = cat.nomeCategoria;

        novaCategoria.acoes.forEach((acao) => {
          delete acao.id;
          delete acao.idAcao;
          delete acao.descricao;
          acao.idCategoria = novaCategoria.id;
          acao.categoria = copyObject(cat);
          acao.categoria.nomExtensao = acao.categoria.nomeCategoria;
          acao.categoria.label = 'categoria';
          acao.arvoreProduto = novaCategoria.nomeCategoria;
          if (acao.metas && acao.metas.length) {
            for (let i = 0; i < acao.metas.length; i += 1) {
              delete acao.metas[i].id;
              delete acao.metas[i].idAcao;

              if (acao[i]) {
                delete acao[i].id;
                delete acao[i].idAcao;
              }
            }
          }
        });
        listaNovasCategorias.push(novaCategoria);
      });
      this.$emit('PlanejamentoContratoFormAcoesControleCategoria__CadastrarCopiaCategoria', listaNovasCategorias);
    },
    confirmaRemoverCategoria(categoria) {
      this.categoriaSelecionada = categoria;
      this.mensagemCancelar = this.$tc('message.deseja_excluir_categoria', 1);
      this.funcCancelar = this.removeCategoria;
      setTimeout(() => this.$refs.removerCategoriaDialog.open());
    },
    removeCategoria() {
      const categoria = this.categoriaSelecionada;
      if (this.idContrato) {
        this.$emit('PlanejamentoContratoFormAcoesControlesItens__pushCategoriaRemovida', categoria);
      }

      this.listaCategoriasCopia.splice(this.listaCategoriasCopia.findIndex((c) => c.id === categoria.id), 1);
      this.categoriasAdicionadas.splice(this.categoriasAdicionadas.findIndex((c) => c.id === categoria.id), 1);

      this.$emit(
        'PlanejamentoContratoFormAcoesControle__RemoveCategoria',
        categoria,
      );
    },
    confirmarExecutarAnalise(categoria) {
      this.categoriaSelecionada = categoria;
      setTimeout(() => this.$refs.executarAnaliseDialog.open());
    },
    executarAnalise() {
      const categoria = this.categoriaSelecionada;
      const dadosCategoria = {
        index,
        idCategoria: categoria.id,
      };

      this.$emit('PlanejamentoContratoFormAcoesControle__ExecutarAnalise', dadosCategoria);
    },
    preencherJustificativa(categoria, justificativa) {
      categoria.justificativa = justificativa;
    },
    confirmaCancelarCategoria(categoria) {
      this.categoriaSelecionada = categoria;
      this.mensagemCancelar = this.$tc('message.deseja_cancelar_categoria', 1);
      this.funcCancelar = this.cancelarCategoria;
      setTimeout(() => this.$refs.cancelarCategoriaDialog.open());
    },
    cancelarCategoria(justificativa) {
      const categoria = this.categoriaSelecionada;
      const dadosCategoria = {
        justificativa,
        idCategoria: categoria.id,
        usuarioCancelamento: this.getUsuario,
      };

      this.$emit('PlanejamentoContratoFormAcoesControle__cancelarCategoria', dadosCategoria);
    },
    podeRemoverCategoria() {
      return this.contratoEmCadastro
        && !!this.getAllRoles.filter((el) => el === 'CONTRATO_CRUD').length
        && this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    podeCancelarFluxo(contrato) {
      return this.contratoAguardandoAprovacao(contrato)
        && contrato.id
        && !!this.getAllRoles.filter((el) => el === 'CONTRATO_CRUD').length
        && this.usuarioLogado.tipo === 'FUNCIONARIO'
        && !this.temFluxoContrato;
    },
    executaSolicitarAprovacao(index = null) {
      if (!index && index !== 0) {
        index = this.categoriaSelecionada.index;
      }
      const categoria = this.categorias[index];
      if (!categoria.id) {
        this.mensagemAviso = this.$t('message.salvar_contrato_antes_solicitar_aprovacao');
        this.$refs.mensagensAvisoDialog.open();
        return;
      }
      const resource = this.workflowAcaoResource.buscaPassosFornecedor;

      this.categoriaSelecionada = categoria;
      const dadosCategoria = {
        idCategoria: categoria.id,
      };

      this.idCategoriaFluxo = categoria.id;

      resource(dadosCategoria)
        .then((response) => {
          const passosCliente = response.data;
          if (passosCliente.length === 0) {
            this.iniciaFluxo();
          } else if (passosCliente.length > 1) {
            const msg = this.$t('errors.acordo.multiplos_passos_fornecedor');
            this.$toast(msg);
          } else if (!passosCliente[0].usuarios.length) {
            this.msgFluxoSemAprovador = this.$t('message.nao_tem_fornecedor_aprovador');
            setTimeout(() => this.$refs.fluxoSemUsuarioClienteDialog.open());
          } else {
            // Implementado suporte para apenas 1 passo cliente/fornecedor
            const [passoCli] = passosCliente;
            this.passoCliente = passoCli;
            this.$refs.modalUsuarioCliente.open();
          }
        }).catch((err) => {
          this.$toast(this.$t(`errors.fluxo.${err.data.error}`));
        });
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const idCategoria = this.idCategoriaFluxo;
      const params = {
        idCategoria,
        idPasso,
        idUsuario,
      };
      const resource = this.workflowAcaoResource.associaUsuarioFornecedorAprovador;
      resource(params, params)
        .then(() => this.iniciaFluxo())
        .catch((err) => this.$error(this, err));
    },
    iniciaFluxo() {
      const idCategoria = this.idCategoriaFluxo;
      this.workflowAcaoResource.iniciaFluxo({ idCategoria }, { idCategoria })
        .then(() => {
          this.recarregarDados(idCategoria, this.categoriaSelecionada);
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    recarregarDados(idCategoria, index) {
      this.planejamentoAcaoListagemResource.buscarStatusAtualizado({ idCategoria })
        .then((res) => {
          const dadosAtualizados = res.data;
          dadosAtualizados.index = categoria;
          if (dadosAtualizados.statusAcao === 'AGUARDANDO_APROVACAO') {
            (this.$refs[`acaoFluxo${index}`][0] || this.$refs[`acaoFluxo${index}`]).verificarUsuarioAprovador(idCategoria, dadosAtualizados.statusAcao);
          }
          if (dadosAtualizados.statusAcao === 'EM_ANALISE') {
            (this.$refs[`acaoFluxo${index}`][0] || this.$refs[`acaoFluxo${index}`]).buscarJustificativa(idCategoria);
          }
          this.$emit('PlanejamentoContratoFormAcoesControle__atualizaDadosFluxoAcao', dadosAtualizados);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
};

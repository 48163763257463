<template>
  <div v-show="paginaCarregada">
    <planejamento-acao-form-resumo
      :exibe-cliente-pagador="exibeClientePagador"
      :objeto-passo-geral="objetoPassoGeralContrato"
      :ordenacao-formulario="ordenacaoFormulario"
      :exibe-fornecedor-pagador="exibeFornecedorPagador"
      :somenteLeitura="somenteLeitura"
      ref="resumo">
      <div class="card-title" slot="titulo-acao">
        {{ $t('label.criacao_contrato')}} :: {{ tituloTipoContrato }} :: {{$tc('title.resumo', 1)}}
      </div>
    </planejamento-acao-form-resumo>

    <planejamento-contrato-form-acoes-campos-comuns
      ref="camposComuns"
      :contrato-edicao="contratoEdicao"
      :configuracao="configuracao"
      :somenteLeitura="somenteLeitura"
      :novo-contrato="novoContrato"
      @PlanejamentoContratoCamposComuns__valorCalculoApuracaoAlterado="valorCalculoApuracaoAlterado">
    </planejamento-contrato-form-acoes-campos-comuns>

    <v-container fluid grid-list-md style="padding: 0;" v-if="!somenteLeitura && canEdit">
      <v-row>
        <v-col cols="12" sm="6" md="4" v-if="!isTipoContratoNivelFamilia">
          <v-tooltip right
            v-model="exibeDica"
            content-class="PlanejamentoContratoFormAcoesControle__Dica">
            <template v-slot:activator="{ on }">
              <v-autocomplete
                id="combo-categorias"
                v-model="categorias"
                :items="listaCategorias"
                chips
                deletable-chips
                multiple
                clearable
                return-object
                autocomplete="off"
                :item-disabled="'atingiuMaximo'"
                item-text="codNome"
                item-value="id"
                :label="$tc('label.categoria', 2)"
                v-on="on">
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-icon v-if="!item.atingiuMaximo">
                    <v-icon>
                      {{ categorias.includes(item) ? 'check_box' : 'check_box_outline_blank' }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-bind:class="{ 'primary--text': item.atingiuMaximo}">
                    <span>{{ item.codNome }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="primary"
                      v-if="item.atingiuMaximo">
                      playlist_add_check
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-autocomplete>
            </template>
            <span>
              {{$t('message.selecione_categoria_para_contrato')}}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-else>
          <v-tooltip right
            v-model="exibeDica"
            content-class="PlanejamentoContratoFormAcoesControle__Dica">
            <template v-slot:activator="{ on }">
              <v-autocomplete
                id="combo-categorias"
                v-model="categoriaSelecionada"
                :items="listaCategorias"
                chips
                deletable-chips
                clearable
                return-object
                autocomplete="off"
                :item-disabled="'atingiuMaximo'"
                item-text="codNome"
                item-value="id"
                @change="verificaCategoriaSelecionada(categoriaSelecionada)"
                :label="$tc('label.categoria', 2)"
                v-on="on">
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-icon v-if="!item.atingiuMaximo">
                    <v-icon>
                      {{ categorias.includes(item) ? 'check_box' : 'check_box_outline_blank' }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-bind:class="{ 'primary--text': item.atingiuMaximo}">
                    <span>{{ item.codNome }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="primary"
                      v-if="item.atingiuMaximo">
                      playlist_add_check
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-autocomplete>
            </template>
            <span>
              {{$t('message.selecione_categoria_para_contrato')}}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6" md="4" style="align-self: center;">
          <v-btn
            @click="comboCategoriaSelecionada"
            color="primary"
            v-show="categorias && categorias.length">
            {{ $tc('label.adicionar', 1) }}
          </v-btn>
        </v-col>
        <v-col class="text-right" cols="12" sm="6" md="4">
          <v-switch
          v-if="!isTipoContratoSamsung && !isTipoContratoNivelFamilia && cadastrarContratoSemVerba && !isTipoContratoEpoca"
          id="config_acao_desc_automatica"
          :style="{ fontWeight: 'bold' }"
          v-model="indSemVerba"
          @change="ativaFlagSemVerba"
          :label="$tc('label.sem_verba').toUpperCase()"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid grid-list-md class="pa-0 pt-3">
      <planejamento-contrato-form-acoes-controle-categoria
        ref="controleCategoria"
        :somente-leitura="somenteLeitura"
        :configuracao="configuracao"
        :contrato-edicao="contratoEdicao"
        :categorias-adicionadas="categoriasAdicionadas"
        :novo-contrato="novoContrato"
        :calculo-volume="calculoApuracaoVolume"
        :calculo-valor="calculoApuracaoValor"
        :calculo-percentual="calculoApuracaoPercentual"
        :lista-categorias="listaCategorias"
        :ind-sem-verba="indSemVerba"
        :get-objeto-outro-passo="getObjetoOutroPasso"
        :objeto-passo-geral-contrato="objetoPassoGeralContrato"
        @PlanejamentoContratoModalCategoriaFornecedor__AbrirModalCategoriaFornecedor="abrirModalCategoriaFornecedor"
        @PlanejamentoContratoFormAcoesControlesItens__pushCategoriaRemovida="novaCategoriaRemovida"
        @PlanejamentoContratoFormAcoesControle__RemoveCategoria="registraRemocaoCategoria"
        @PlanejamentoContratoFormAcoesControle__RemoverAcao="removerAcao">
      </planejamento-contrato-form-acoes-controle-categoria>
    </v-container>

    <v-dialog
      v-model="exibeModalCategoriaFornecedor"
      fullscreen
      hide-overlay
      eager
      persistent
      transition="dialog-bottom-transition">
      <planejamento-contrato-modal-categoria-fornecedor
        ref="modalCategoriaFornecedor"
        :somente-leitura="somenteLeitura"
        :lista-categorias="listaCategorias"
        :objeto-passo-geral-contrato="objetoPassoGeralContrato"
        :configuracao="configuracao"
        @PlanejamentoContratoModalCategoriaFornecedor__CadastrarModalCategoriaFornecedor="validaInsercaoCategoriaNoContrato"
        @PlanejamentoContratoModalCategoriaFornecedor__FecharModalCategoriaFornecedor="fecharModalCategoriaFornecedor">
      </planejamento-contrato-modal-categoria-fornecedor>
    </v-dialog>

    <confirm
      ref="modalConfirmacaoAddCategorias"
      :message="$t('message.confirmacao_inclusao_categorias', { categorias: nomesCategoriasAdicionadas })"
      :persistent="true"
      :title="$t('label.atencao')"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')"
      max-width="45%"
      @agree="confirmaInclusaoCategorias"/>

    <confirm
      ref="modalDuplicidadeContrato"
      :message="$t('message.confirmacao_inclusao_categorias_contrato_duplicado', { categorias: nomesCategoriasDuplicadas })"
      :persistent="true"
      :title="$t('title.duplicidade_contrato')"
      :agree-label="$t('label.ok')"
      hide-disagree
      max-width="65%"
      @agree="confirmaInformacaoDuplicidadeContrato"/>

      <confirm
      ref="modalIndSemVerba"
      :message="mensagemConfirmacaoIndSemVerba"
      :persistent="true"
      :maxWidth="'450'"
      @agree="confirmaAtivacaoFlagSemVerba(true)"
      @disagree="confirmaAtivacaoFlagSemVerba(false)"/>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatDateDDMMYYYY } from '../../../../common/functions/date-utils';
import { generateComputed } from '../../../../common/functions/roles-computed-generator';
import { copyObject } from '../../../../common/functions/helpers';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoAcaoFormResumo from '../../../planejamento-acao/form/PlanejamentoAcaoFormResumo';
import PlanejamentoContratoFormAcoesCamposComuns from './PlanejamentoContratoFormAcoesCamposComuns';
import PlanejamentoContratoFormAcoesControleCategoria from './PlanejamentoContratoFormAcoesControleCategoria';
import PlanejamentoContratoModalCategoriaFornecedor from './PlanejamentoContratoModalCategoriaFornecedor';

export default {
  props: {
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    novoContrato: Boolean,
    podeSolicitarAprovacaoContrato: Boolean,
    temFluxoContrato: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    objetoPassoGeralContrato: {
      type: Object,
      required: true,
    },
    alterarFinalizado: Boolean,
    edicao: Boolean,
    getObjetoFormatado: Function,
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  components: {
    PlanejamentoAcaoFormResumo,
    PlanejamentoContratoFormAcoesCamposComuns,
    PlanejamentoContratoFormAcoesControleCategoria,
    PlanejamentoContratoModalCategoriaFornecedor,
    Confirm,
  },
  computed: {
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    cadastrarContratoSemVerba() {
      return this.getAllRoles
        .filter((el) => el.indexOf('CADASTRAR_CONTRATO_SEM_VERBA_CONF') !== -1).length > 0;
    },
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador;
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador;
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    tituloTipoContrato() {
      return this.configuracao.nome;
    },
    idContrato() {
      const { id } = this.contratoEdicao;
      return id || null;
    },
    contratoAprovado() {
      return this.idContrato && this.contratoEdicao && this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status
        && this.contratoEdicao.passoGeral.status === 'APROVADO';
    },
    contratoEmCadastro() {
      return !this.idContrato || (this.contratoEdicao && this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status
        && this.contratoEdicao.passoGeral.status) === 'EM_CADASTRO';
    },
    isTemplateSomaValorMonetarioAcoes() {
      return this.configuracao.configuracao
        && this.configuracao.configuracao.passo2
        && this.configuracao.configuracao.passo2.template === 'SOMA_VALOR_MONETARIO_ACOES';
    },
    isTipoContratoSamsung() {
      return this.configuracao.isTipoContratoSamsung;
    },
    isTipoContratoEpoca() {
      return this.configuracao.isTipoContratoEpoca;
    },
    isTipoContratoNivelFamilia() {
      return this.configuracao.isTipoContratoNivelFamilia;
    },
  },
  data() {
    return {
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      categorias: [],
      categoriaSelecionada: null,
      listaCategorias: [],
      controleCategoria: {},
      categoriasAdicionadas: [],
      acoesRemovidas: [],
      categoriasRemovidas: [],

      acoes: [],

      exibeDica: false,

      paginaCarregada: false,

      exibeModalCategoriaFornecedor: false,
      categoriaAbertaModal: null,

      calculoApuracaoValor: true,
      calculoApuracaoVolume: false,
      calculoApuracaoPercentual: false,

      nomesCategoriasAdicionadas: '',
      nomesCategoriasDuplicadas: '',
      idsCategoriasDuplicadas: [],
      isValidacaoDuplicidadeHabilitada: true,
      indSemVerba: false,
      mensagemConfirmacaoIndSemVerba: null,
      indSemVerbaConfiguracaoInicial: false,
      indVerificaMudancaFlagFalse: false,
      indAlteracaoValorAcaoSemSalvar: false,
    };
  },
  methods: {
    ativaFlagSemVerba(valor) {
      if (valor) {
        this.indVerificaMudancaFlagFalse = false;
        this.mensagemConfirmacaoIndSemVerba = this.$t('message.ativacaoFlagSemVerba');
        setTimeout(() => this.$refs.modalIndSemVerba.open());
      } else {
        this.indVerificaMudancaFlagFalse = true;
        this.$emit('PlanejamentoContratoFormAcoesControle__indSemVerba', valor);
      }
    },
    confirmaAtivacaoFlagSemVerba(valor) {
      if (valor) {
        this.indSemVerba = true;
        this.organizarAcoesSemVerba();
      } else {
        this.indSemVerba = false;
      }
      this.$emit('PlanejamentoContratoFormAcoesControle__indSemVerba', this.indSemVerba);
    },
    comboCategoriaSelecionada() {
      this.ajustaNomesCategoriasASeremAdicionadas();
      if (!this.isValidacaoDuplicidadeHabilitada) {
        setTimeout(() => this.$refs.modalConfirmacaoAddCategorias.open());
      } else {
        this.verificaDuplicidadeContratoAoAdicionarCategoria();
      }
    },
    verificaDuplicidadeContratoAoAdicionarCategoria(categoriaSelecionadaModal = null) {
      let idsCategoriasAdicionadas = '';

      if (categoriaSelecionadaModal === null) {
        this.categorias.forEach((cat) => {
          if (idsCategoriasAdicionadas.length > 0) {
            idsCategoriasAdicionadas = idsCategoriasAdicionadas.concat(',');
          }
          idsCategoriasAdicionadas = idsCategoriasAdicionadas.concat(cat.id);
        });
      } else {
        categoriaSelecionadaModal.idsCategoriasAgrupadas.forEach((idCatAgrupada) => {
          if (idsCategoriasAdicionadas.length > 0) {
            idsCategoriasAdicionadas = idsCategoriasAdicionadas.concat(',');
          }
          idsCategoriasAdicionadas = idsCategoriasAdicionadas.concat(idCatAgrupada);
        });
      }

      const params = {
        dataInicio: this.objetoPassoGeralContrato.dtaVigenciaInicio,
        dataFim: this.objetoPassoGeralContrato.dtaVigenciaFim,
        idsCategorias: idsCategoriasAdicionadas,
        idContratoTipo: this.objetoPassoGeralContrato.idContratoTipo,
      };
      if (this.idContrato) {
        params.idContrato = this.idContrato;
      }
      if (this.objetoPassoGeralContrato.grupoFornecedor
        && this.objetoPassoGeralContrato.grupoFornecedor.id) {
        params.idGrupoFornecedor = this.objetoPassoGeralContrato.grupoFornecedor.id;
      } else {
        params.idFornecedor = this.objetoPassoGeralContrato.fornecedor.id;
      }
      this.planejamentoContratoResource.verificaDuplicidadeContrato(params)
        .then((res) => {
          const listaContratoDuplicados = res.data;
          if (listaContratoDuplicados && listaContratoDuplicados.length) {
            this.nomesCategoriasDuplicadas = '';
            this.idsCategoriasDuplicadas = listaContratoDuplicados.map((cat) => cat.idCategoria);
            listaContratoDuplicados.forEach((categoriaDuplicada) => {
              this.nomesCategoriasDuplicadas = this.nomesCategoriasDuplicadas
                .concat('<ul><li>')
                .concat(categoriaDuplicada.nomeCategoria)
                .concat('<ul><li>')
                .concat(this.$tc('message.confirmacao_inclusao_categorias_contrato_duplicado_avaliar_contrato', 1))
                .concat('</li>');
              const contratosDuplicadosNaCategoria = categoriaDuplicada.dadosContrato.split(';;');
              contratosDuplicadosNaCategoria.forEach((contrato) => {
                this.nomesCategoriasDuplicadas = this.nomesCategoriasDuplicadas
                  .concat('<li>')
                  .concat(`<button class="PlanejamentoContratoFormAcoesControle__categoria_duplicada_btn" id="${categoriaDuplicada.id}"">${categoriaDuplicada.id}</button> ${contrato}`)
                  .concat('</li>');
              });
              this.nomesCategoriasDuplicadas = this.nomesCategoriasDuplicadas
                .concat('</ul></li></ul>');
            });
            setTimeout(() => this.$refs.modalDuplicidadeContrato.openWithParams(
              this.$t('title.duplicidade_contrato'),
              null,
              () => {},
            ));
          } else if (categoriaSelecionadaModal === null) {
            setTimeout(() => this.$refs.modalConfirmacaoAddCategorias.open());
          } else {
            this.inserirCategoriaNoContrato(categoriaSelecionadaModal);
          }

          setTimeout(() => document.querySelectorAll('.PlanejamentoContratoFormAcoesControle__categoria_duplicada_btn').forEach((item) => {
            item.addEventListener('click', this.abreNovaAbaPlanejamento);
          }), 1000);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abreNovaAbaPlanejamento(item) {
      const route = this.$router.resolve({ name: 'planejamento-contrato', params: { idContrato: item.target.id, from: 'dashboard-inicial', carregaPassoAcoes: true } });
      const newTab = window.open(route.href, '_blank');
      if (newTab) {
        localStorage.setItem('idContratoDuplicidade', item.target.id);
      }
    },
    confirmaInformacaoDuplicidadeContrato(insercaoModalCategoriaExistente = null) {
      if (!insercaoModalCategoriaExistente
        && this.categorias.length && this.idsCategoriasDuplicadas.length !== this.categorias.length) {
        this.categoriaAbertaModal = null;
        this.categorias = this.categorias.filter((cat) => !this.idsCategoriasDuplicadas.includes(cat.id));
        this.ajustaNomesCategoriasASeremAdicionadas();
        setTimeout(() => this.$refs.modalConfirmacaoAddCategorias.open());
      }
    },
    confirmaInclusaoCategorias() {
      this.categorias.forEach((categoria) => {
        categoria.idsCategoriasAgrupadas = this.categorias.map((c) => c.id).sort((a, b) => a - b);
      });
      this.categorias.sort((a, b) => a.id - b.id);
      this.abrirModalCategoriaFornecedor(this.categorias[0], false);
    },
    ajustaNomesCategoriasASeremAdicionadas() {
      this.nomesCategoriasAdicionadas = '';
      this.categorias.forEach((categoria) => {
        this.nomesCategoriasAdicionadas = this.nomesCategoriasAdicionadas.concat('<ul><li>').concat(categoria.nomeCategoria).concat('</li></ul>');
      });
    },
    abrirModalCategoriaFornecedor(categoria, permiteAlterarCategoriaModal) {
      if (permiteAlterarCategoriaModal) {
        this.categoriaAbertaModal = copyObject(categoria);
      } else {
        this.categoriaAbertaModal = null;
      }
      this.$refs.modalCategoriaFornecedor.setarPermissaoAlterarCategorias(permiteAlterarCategoriaModal);
      this.$refs.modalCategoriaFornecedor.setarCategoria(categoria);
      if (permiteAlterarCategoriaModal) {
        categoria.idsCategoriasAgrupadasOriginal = [...categoria.idsCategoriasAgrupadas];
      }
      setTimeout(this.exibeModalCategoriaFornecedor = true, 500);
    },
    fecharModalCategoriaFornecedor(categoria = null) {
      if (categoria != null && categoria.idsCategoriasAgrupadasOriginal) {
        categoria.idsCategoriasAgrupadas = [...categoria.idsCategoriasAgrupadasOriginal];
      }
      this.exibeModalCategoriaFornecedor = false;
      this.categorias = [];
    },
    validaInsercaoCategoriaNoContrato(categoriaSelecionadaModal) {
      if (!this.isValidacaoDuplicidadeHabilitada) {
        this.inserirCategoriaNoContrato(categoriaSelecionadaModal);
      } else {
        this.verificaDuplicidadeContratoAoAdicionarCategoria(categoriaSelecionadaModal);
      }
    },
    inserirCategoriaNoContrato(categoria, listaContratoDuplicados = null) {
      if (listaContratoDuplicados != null) {
        categoria.idsCategoriasAgrupadas = categoria.idsCategoriasAgrupadas
          .filter((idCat) => !listaContratoDuplicados.map((catDuplicada) => catDuplicada.idCategoria).includes(idCat));
      }
      if (categoria.idsCategoriasAgrupadas && categoria.idsCategoriasAgrupadas.length) {
        if (this.categoriasAdicionadas.filter((cat) => cat.id === categoria.id).length > 0) {
          this.atualizarCategoriaNoContrato(categoria);
        } else {
          categoria.idsCategoriasAgrupadas.forEach((id) => {
            const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === id);
            if (!categoriaAgrupada.acoes || !categoriaAgrupada.acoes.length) {
              this.inserirTiposAcaoNaCategoria(categoriaAgrupada);
            }
          });
          this.registraCategoriaNoContrato(categoria);
        }
        this.fecharModalCategoriaFornecedor();
      }
    },
    verificaCategoriaSelecionada(categoria) {
      if (!categoria) {
        this.categorias = [];
      } else {
        this.categorias = [categoria];
      }
    },
    atualizarCategoriaNoContrato(categoria) {
      categoria.idsCategoriasAgrupadas.sort((a, b) => a - b);

      let categoriaPrincipal = categoria;
      if (!categoriaPrincipal.idsCategoriasAgrupadas.includes(categoria.id)
        || categoriaPrincipal.id !== categoria.idsCategoriasAgrupadas[0]) {
        categoriaPrincipal = this.listaCategorias.find((cat) => cat.id === categoriaPrincipal.idsCategoriasAgrupadas[0]);
      }
      const categoriaOriginal = this.categoriaAbertaModal != null
        ? this.categoriaAbertaModal : this.listaCategorias.find((cat) => cat.id === categoria.id);

      categoriaOriginal.idsCategoriasAgrupadas.forEach((id) => {
        const controle = this.controleCategoria[id];
        controle.qtdeAtual -= 1;
        this.verificaSeCategoriaDeveTravar(id);
      });
      categoria.idsCategoriasAgrupadas.forEach((id) => {
        const controle = this.controleCategoria[id];
        controle.qtdeAtual += 1;
        this.verificaSeCategoriaDeveTravar(id);

        const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === id);
        if (!categoriaAgrupada.acoes || !categoriaAgrupada.acoes.length) {
          this.inserirTiposAcaoNaCategoria(categoriaAgrupada);
        }
      });

      categoriaPrincipal.fornecedores = categoria.fornecedores;
      categoriaPrincipal.idsCategoriasRemovidas = categoriaOriginal.idsCategoriasAgrupadas
        .filter((id) => !categoria.idsCategoriasAgrupadas.includes(id));
      categoriaPrincipal.idsCategoriasAgrupadas = [...categoria.idsCategoriasAgrupadas];

      if (categoriaPrincipal.nomeCategoria.includes(categoriaOriginal.nomeCategoria)) {
        categoriaPrincipal.nomeCategoria = categoriaOriginal.nomeCategoria;
      }

      if (categoriaPrincipal.idsCategoriasAgrupadas[0] !== categoriaOriginal.idsCategoriasAgrupadas[0]) {
        this.atualizaAcoesCategoriaPrincipal(categoriaPrincipal, categoria);
      }

      this.categoriasAdicionadas.splice(this.categoriasAdicionadas.findIndex((cat) => cat.id === categoria.id), 1, categoriaPrincipal);

      this.atualizaListaCategoriasAdicionadas();
    },
    inserirTiposAcaoNaCategoria(categoria) {
      categoria.acoes = copyObject(this.configuracao.tiposAcao);
      const camposComuns = this.$refs.camposComuns.getObjetoCamposComuns();
      const { calculoApuracao } = camposComuns;
      categoria.acoes.forEach((acao) => {
        acao.calculoApuracaoAlterado = false;
        if (!this.isTipoContratoSamsung && !this.isTipoContratoNivelFamilia && !this.isTipoContratoEpoca) {
          if (!calculoApuracao) {
            acao.calculoApuracao = 'SELLIN_VALOR';
          } else {
            acao.calculoApuracao = calculoApuracao;
          }
        }
      });
      function compare(a, b) {
        if (a.nomeAcaoTipo.toLowerCase() < b.nomeAcaoTipo.toLowerCase()) {
          return -1;
        }
        if (a.nomeAcaoTipo.toLowerCase() > b.nomeAcaoTipo.toLowerCase()) {
          return 1;
        }
        return 0;
      }
      categoria.acoes.sort(compare);
    },
    atualizaAcoesCategoriaPrincipal(categoriaPrincipal, categoriaAlterada) {
      categoriaAlterada.acoes.forEach((acaoAlterada) => {
        if ((!!acaoAlterada.valorBonificacao && acaoAlterada.valorBonificacao > 0)
          || (!!acaoAlterada.metas && acaoAlterada.metas.length)) {
          categoriaPrincipal.acoes.forEach((acaoPrincipal) => {
            if (acaoPrincipal.idAcaoTipo === acaoAlterada.idAcaoTipo) {
              const {
                configuracao,
                formaBonificacao,
                idConfiguracao,
                metas,
                meta,
                periodosApuracao,
                valor,
                valorBonificacao,
              } = acaoAlterada;

              acaoPrincipal.configuracao = configuracao;
              acaoPrincipal.formaBonificacao = formaBonificacao;
              acaoPrincipal.fornecedores = [...categoriaPrincipal.fornecedores];
              acaoPrincipal.idConfiguracao = idConfiguracao;
              acaoPrincipal.idCategoria = categoriaPrincipal.id;
              acaoPrincipal.idsCategoriasAgrupadas = categoriaPrincipal.idsCategoriasAgrupadas.toString();
              acaoPrincipal.metas = metas;
              acaoPrincipal.meta = meta;
              acaoPrincipal.periodosApuracao = periodosApuracao;
              acaoPrincipal.valor = valor;
              acaoPrincipal.valorBonificacao = valorBonificacao;

              if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
                const {
                  dtaInicio,
                  dtaFim,
                  calculoApuracao,
                  calculoBonificacao,
                  idCopia,
                } = acaoAlterada;

                acaoPrincipal.dtaInicio = dtaInicio;
                acaoPrincipal.dtaFim = dtaFim;
                acaoPrincipal.dtaInicioFormatada = formatDateDDMMYYYY(dtaInicio);
                acaoPrincipal.dtaFimFormatada = formatDateDDMMYYYY(dtaFim);
                acaoPrincipal.calculoApuracao = calculoApuracao;
                acaoPrincipal.calculoBonificacao = calculoBonificacao;
                acaoPrincipal.idCopia = idCopia;
              }

              if (acaoPrincipal.metas && acaoPrincipal.metas.length) {
                acaoPrincipal.metas.forEach((m) => {
                  delete m.id;
                  delete m.index;
                });
              }
            }
          });
        }
      });
    },
    valorCalculoApuracaoAlterado(valorCalculoApuracao) {
      if (valorCalculoApuracao
        && (valorCalculoApuracao === 'SELLIN_VOLUME' || valorCalculoApuracao === 'SELLOUT_VOLUME')) {
        this.calculoApuracaoVolume = true;
        this.calculoApuracaoValor = false;
        this.calculoApuracaoPercentual = false;
      } else if (valorCalculoApuracao
        && (valorCalculoApuracao === 'SELLIN_VALOR' || valorCalculoApuracao === 'SELLOUT_VALOR')) {
        this.calculoApuracaoVolume = false;
        this.calculoApuracaoValor = true;
        this.calculoApuracaoPercentual = false;
      } else {
        this.calculoApuracaoVolume = false;
        this.calculoApuracaoValor = false;
        this.calculoApuracaoPercentual = true;
      }
      if (valorCalculoApuracao) {
        this.verificarMudancaCalculoEmAcoesIntervaloPreenchidas(valorCalculoApuracao);
        this.exibeDica = this.novoContrato;
      }
    },
    verificarMudancaCalculoEmAcoesIntervaloPreenchidas(valorCalculoApuracao) {
      this.categoriasAdicionadas.forEach((cat) => {
        cat.acoes.filter((a) => !!a.configuracao && a.configuracao.meta === 'INTERVALO'
          && a.configuracao.templateCalculo !== 'META_POR_QUANTIDADE'
          && a.metas && a.metas.length).forEach((acao) => {
          if (acao.calculoApuracao !== valorCalculoApuracao) {
            acao.calculoApuracaoAlterado = true;
            acao.calculoApuracao = valorCalculoApuracao;
          }
        });
      });
    },
    setarCamposContratoSamsung(acao) {
      const { dtaVigenciaInicio, dtaVigenciaFim } = this.objetoPassoGeralContrato;
      acao.dtaInicio = dtaVigenciaInicio;
      acao.dtaFim = dtaVigenciaFim;
      acao.dtaInicioFormatada = formatDateDDMMYYYY(dtaVigenciaInicio);
      acao.dtaFimFormatada = formatDateDDMMYYYY(dtaVigenciaFim);
    },
    validaCamposComuns() {
      return this.$refs.camposComuns.valida();
    },
    validaAcoes() {
      return this.$refs.controleCategoria.valida();
    },
    validaAcoesDuplicadasCategoriasNaoAgrupadas() {
      return this.$refs.controleCategoria.validaAcoesDuplicadasCategoriasNaoAgrupadas();
    },
    validaAcoesDuplicadasMesmaCategoria() {
      return this.$refs.controleCategoria.validaAcoesDuplicadasMesmaCategoria();
    },
    validaAcoesDuplicadasSemVerba() {
      return this.$refs.controleCategoria.validaAcoesDuplicadasSemVerba();
    },
    validaDuplicidadeContratoAoSalvar() {
      if (!this.isValidacaoDuplicidadeHabilitada) {
        return Promise.resolve(true);
      }

      this.categorias = [];
      let idsCategoriasAdicionadas = '';
      this.categoriasAdicionadas.forEach((cat) => {
        cat.idsCategoriasAgrupadas.forEach((idCatAgrupada) => {
          if (idsCategoriasAdicionadas.length > 0) {
            idsCategoriasAdicionadas = idsCategoriasAdicionadas.concat(',');
          }
          idsCategoriasAdicionadas = idsCategoriasAdicionadas.concat(idCatAgrupada);
        });
      });

      const params = {
        dataInicio: this.objetoPassoGeralContrato.dtaVigenciaInicio,
        dataFim: this.objetoPassoGeralContrato.dtaVigenciaFim,
        idsCategorias: idsCategoriasAdicionadas,
        idContratoTipo: this.objetoPassoGeralContrato.idContratoTipo,
      };
      if (this.idContrato) {
        params.idContrato = this.idContrato;
      }
      if (this.objetoPassoGeralContrato.grupoFornecedor
        && this.objetoPassoGeralContrato.grupoFornecedor.id) {
        params.idGrupoFornecedor = this.objetoPassoGeralContrato.grupoFornecedor.id;
      } else {
        params.idFornecedor = this.objetoPassoGeralContrato.fornecedor.id;
      }
      return this.planejamentoContratoResource.verificaDuplicidadeContrato(params)
        .then((res) => {
          const listaContratoDuplicados = res.data;
          if (listaContratoDuplicados && listaContratoDuplicados.length) {
            this.nomesCategoriasDuplicadas = '';
            this.idsCategoriasDuplicadas = listaContratoDuplicados.map((cat) => cat.idCategoria);
            listaContratoDuplicados.forEach((categoriaDuplicada) => {
              this.nomesCategoriasDuplicadas = this.nomesCategoriasDuplicadas
                .concat('<ul><li>')
                .concat(categoriaDuplicada.nomeCategoria)
                .concat('<ul><li>')
                .concat(this.$tc('message.confirmacao_inclusao_categorias_contrato_duplicado_avaliar_contrato', 1))
                .concat('</li>');
              const contratosDuplicadosNaCategoria = categoriaDuplicada.dadosContrato.split(';;');
              contratosDuplicadosNaCategoria.forEach((contrato) => {
                this.nomesCategoriasDuplicadas = this.nomesCategoriasDuplicadas
                  .concat('<li>')
                  .concat(contrato)
                  .concat('</li>');
              });
              this.nomesCategoriasDuplicadas = this.nomesCategoriasDuplicadas
                .concat('</ul></li></ul>');
            });
            setTimeout(() => this.$refs.modalDuplicidadeContrato.open());
            return false;
          }
          return true;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    registraRemocaoCategoria(categoria) {
      categoria.idsCategoriasAgrupadas.forEach((id) => {
        this.controleCategoria[id].qtdeAtual -= 1;
      });
      this.recalcularQtdeMaxCategoria(categoria);
      const idsCategoriasAgrupadas = copyObject(categoria.idsCategoriasAgrupadas);
      idsCategoriasAgrupadas.forEach((idCatAgrupada) => {
        const cat = this.listaCategorias.find((c) => c.id === idCatAgrupada);
        cat.idsCategoriasAgrupadas = [];
        cat.acoes = [];
      });
      if (this.$refs && this.$refs.controleCategoria) {
        this.$refs.controleCategoria.listaFamilias = [];
      }
    },
    carregaListaCategorias() {
      return this.planejamentoContratoResource.buscarCategoria()
        .then((res) => {
          res.data.forEach((cat) => {
            this.listaCategorias.push({
              ...cat,
              fornecedores: [],
              idsCategoriasAgrupadas: [],
              atingiuMaximo: false,
            });
          });
        });
    },
    registraCategoriaNoContrato(categoria) {
      categoria.idsCategoriasAgrupadas.forEach((id) => {
        const controle = this.controleCategoria[id];
        controle.qtdeAtual += 1;
        this.verificaSeCategoriaDeveTravar(id);
      });

      this.categoriasAdicionadas.push(categoria);
    },
    verificaSeCategoriaDeveTravar(id) {
      const { qtdeMax, qtdeAtual } = this.controleCategoria[id];
      const categoria = this.listaCategorias.find((cat) => cat.id === id);

      if (qtdeMax <= qtdeAtual) {
        categoria.atingiuMaximo = true;
      } else {
        categoria.atingiuMaximo = false;
      }
    },
    recalcularQtdeMaxCategoria(categoria) {
      categoria.idsCategoriasAgrupadas.forEach((id) => this.verificaSeCategoriaDeveTravar(id));
    },
    novaCategoriaRemovida(categoria) {
      this.categoriasRemovidas.push(...categoria.idsCategoriasAgrupadas);
      const idsCategoriasAgrupadas = copyObject(categoria.idsCategoriasAgrupadas);
      idsCategoriasAgrupadas.forEach((idCatAgrupada) => {
        const cat = this.listaCategorias.find((c) => c.id === idCatAgrupada);
        cat.acoes = [];
        cat.fornecedores = [];
      });
    },
    carregaCategoriasEdicaoContrato() {
      const { passoAcoes, passoGeral } = this.contratoEdicao;
      const { acoes } = passoAcoes;
      this.confirmaAtivacaoFlagSemVerba(passoGeral.indSemVerba);
      this.indSemVerbaConfiguracaoInicial = passoGeral.indSemVerba;

      if (acoes) {
        this.acoes = acoes.map((objAcao) => {
          const objRetorno = {
            ...objAcao,
            ...objAcao.mapaExtensoes,
            ...objAcao.mapaCamposDinamicos,
            ...objAcao.mapaExcecoes,
            ...objAcao.metas,
            idsCategoriasAgrupadas: objAcao.idsCategoriasAgrupadas.split(',').map(Number),
          };
          if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
            objRetorno.dtaInicioFormatada = formatDateDDMMYYYY(objAcao.dtaInicio);
            objRetorno.dtaFimFormatada = formatDateDDMMYYYY(objAcao.dtaFim);
          }
          return objRetorno;
        });

        if (!this.isTipoContratoSamsung && !this.isTipoContratoNivelFamilia && !this.isTipoContratoEpoca) {
          this.valorCalculoApuracaoAlterado(this.acoes[0].calculoApuracao);
        }
        this.registraCategoriasEdicaoContrato();
      }
    },
    registraCategoriasEdicaoContrato() {
      if (this.listaCategorias !== null && this.listaCategorias.length) {
        this.acoes
          .map((acao) => acao.idCategoria)
          .map((idCategoria) => this.listaCategorias
            .filter((cat) => cat.id === idCategoria)[0])
          .forEach((categoriaPrincipal) => {
            if (!categoriaPrincipal) {
              this.$toast(this.$t('message.categoria_inativada'));
            } else if (!this.categoriasAdicionadas.some((cat) => cat.id === categoriaPrincipal.id)) {
              /**
               * Só registra categoria que não esteja agrupada ou,
               * caso existam categorias agrupadas, registra apenas a primeira categoria da lista de ids agrupados
               */
              categoriaPrincipal.idsCategoriasAgrupadas = [...this.acoes
                .filter((acao) => acao.idCategoria === categoriaPrincipal.id)[0].idsCategoriasAgrupadas];
              if (categoriaPrincipal.id === categoriaPrincipal.idsCategoriasAgrupadas[0]) {
                this.registraCategoriaNoContrato(categoriaPrincipal);
                this.registraAcoesNaCategoria(categoriaPrincipal);
              } else {
                categoriaPrincipal.idsCategoriasAgrupadas.forEach((id) => {
                  if (id !== categoriaPrincipal.idsCategoriasAgrupadas[0]) {
                    const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === id);
                    this.registraAcoesNaCategoria(categoriaAgrupada);
                  }
                });
              }
            }
          });
      }
    },
    registraAcoesNaCategoria(categoria) {
      categoria.acoes = this.acoes.filter((acao) => acao.idCategoria === categoria.id);
      categoria.fornecedores = categoria.acoes[0].fornecedores;
      this.insereTiposAcaoFaltantes(categoria);
    },
    insereTiposAcaoFaltantes(categoria) {
      const acoesNaoAdicionadasNaCategoria = this.configuracao.tiposAcao
        .filter((tipoAcaoConfig) => categoria.acoes.some((acaoCategoria) => tipoAcaoConfig.idAcaoTipo === acaoCategoria.idAcaoTipo) === false);

      const camposComuns = this.$refs.camposComuns.getObjetoCamposComuns();
      const { calculoApuracao } = camposComuns;
      acoesNaoAdicionadasNaCategoria.forEach((acao) => {
        acao.idsCategoriasAgrupadas = [...categoria.idsCategoriasAgrupadas];
        acao.calculoApuracaoAlterado = false;
        if (!this.isTipoContratoSamsung && !this.isTipoContratoNivelFamilia && !this.isTipoContratoEpoca) {
          if (!calculoApuracao) {
            acao.calculoApuracao = 'SELLIN_VALOR';
          } else {
            acao.calculoApuracao = calculoApuracao;
          }
        }
      });

      categoria.acoes = [
        ...categoria.acoes,
        ...copyObject(acoesNaoAdicionadasNaCategoria),
      ];

      function compare(a, b) {
        if (a.nomeAcaoTipo.toLowerCase() < b.nomeAcaoTipo.toLowerCase()) {
          return -1;
        }
        if (a.nomeAcaoTipo.toLowerCase() > b.nomeAcaoTipo.toLowerCase()) {
          return 1;
        }
        return 0;
      }
      categoria.acoes.sort(compare);
    },
    atualizaCancelamento(dadosCategoria) {
      const categoria = this.categoriasAdicionadas[dadosCategoria.index];
      categoria.justificativaCancelamento = dadosCategoria.justificativa;
      categoria.status = 'CANCELADO';
      categoria.usuarioCancelamento = dadosCategoria.usuarioCancelamento;
      this.categoriasAdicionadas[dadosCategoria.index] = categoria;
      this.atualizaListaCategoriasAdicionadas();
      this.registraRemocaoCategoria(categoria);
    },
    atualizaListaCategoriasAdicionadas() {
      setTimeout(() => {
        this.$refs.controleCategoria.setCategorias();
      });
    },
    removerCategoriasPorOrigemAlterada() {
      this.categoriasAdicionadas.forEach((categoria) => {
        if (this.idContrato) {
          this.novaCategoriaRemovida(categoria);
        }
        this.registraRemocaoCategoria(categoria);
      });
      this.categoriasAdicionadas = [];
      this.categorias = [];
      this.$forceUpdate();

      setTimeout(() => {
        this.atualizaListaCategoriasAdicionadas();
      });
    },
    setarExibeDica(valor) {
      this.exibeDica = valor;
    },
    montaControleDeCategoria() {
      this.listaCategorias.forEach((cat) => {
        this.controleCategoria[cat.id] = {
          qtdeMax: 1,
          qtdeAtual: 0,
        };
      });
    },
    acoesParaEnvio() {
      const passoAcoes = {
        acoes: this.acoes,
        acoesRemovidas: this.acoesRemovidas,
        categoriasRemovidas: this.categoriasRemovidas,
      };
      return passoAcoes;
    },
    organizarAcoesPreenchidas() {
      this.acoes = [];
      let categoriaPrincipal = {
        id: 0,
      };

      this.$refs.controleCategoria.obterCategoriasPreenchidas().forEach((categoria) => {
        if (this.indSemVerba) {
          categoria.acoes = this.categoriaVerificarAcoesRepetidaSemVerba(categoria);
        }
        if (categoria.idsCategoriasAgrupadas.length > 1) {
          if (categoriaPrincipal.id === 0
            || (categoriaPrincipal.id !== categoria.idsCategoriasAgrupadas[0]
            && categoria.id === categoria.idsCategoriasAgrupadas[0])) {
            categoriaPrincipal = copyObject(categoria);
            this.formatarAcoesCategoriaPrincipal(categoriaPrincipal);
          }

          categoria.idsCategoriasAgrupadas.forEach((idCategoriaAgrupada) => {
            if (idCategoriaAgrupada !== categoria.idsCategoriasAgrupadas[0]) {
              this.formatarAcoesCategoriaAgrupada(idCategoriaAgrupada, categoriaPrincipal);
            }
          });
        } else {
          this.formatarAcoesCategoriaPrincipal(categoria);
        }
      });
    },
    organizarAcoesSemVerba() {
      this.acoes = [];
      let categoriaPrincipal = {
        id: 0,
      };

      this.$refs.controleCategoria.obterCategoriasPreenchidas().forEach((categoria) => {
        if (categoria.idsCategoriasAgrupadas.length > 1) {
          if (categoriaPrincipal.id === 0
            || (categoriaPrincipal.id !== categoria.idsCategoriasAgrupadas[0]
            && categoria.id === categoria.idsCategoriasAgrupadas[0])) {
            categoriaPrincipal = copyObject(categoria);
            this.formatarAcoesCategoriaSemVerba(categoria);
          }

          categoria.idsCategoriasAgrupadas.forEach((idCategoriaAgrupada) => {
            if (idCategoriaAgrupada !== categoria.idsCategoriasAgrupadas[0]) {
              this.formatarAcoesCategoriaSemVerbaAgrupada(idCategoriaAgrupada, categoria);
            }
          });
        } else {
          this.formatarAcoesCategoriaSemVerba(categoria);
        }
      });
    },
    categoriaVerificarAcoesRepetidaSemVerba(categoria) {
      const tiposAcaoSet = new Set();
      const acoesSemRepeticao = [];

      categoria.acoes.forEach((acao) => {
        if (!tiposAcaoSet.has(acao.idAcaoTipo)) {
          tiposAcaoSet.add(acao.idAcaoTipo);
          acoesSemRepeticao.push(acao);
        }
      });
      return acoesSemRepeticao;
    },
    formatarAcoesCategoriaSemVerba(categoria) {
      categoria.acoes.forEach((acao) => {
        if ((!!acao.valorBonificacao && acao.valorBonificacao > 0)
          || (!!acao.metas && acao.metas.length)) {
          acao.periodosApuracao = 'UNICA_VEZ';
          acao.valor = 0;
          acao.metas = [];
          acao.valorBonificacao = 0;
          acao.formaBonificacao = '';
        }
      });
    },
    formatarAcoesCategoriaSemVerbaAgrupada(idCategoriaAgrupada, categoriaPrincipal) {
      const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === idCategoriaAgrupada);
      categoriaPrincipal.acoes.forEach((acao) => {
        categoriaAgrupada.acoes.forEach((acaoAgrupada) => {
          if (acao.idAcaoTipo === acaoAgrupada.idAcaoTipo
            && ((!acao.idCopia && !acaoAgrupada.idCopia) || acao.idCopia === acaoAgrupada.idCopia)) {
            if ((!!acao.valorBonificacao && acao.valorBonificacao > 0)
             || (!!acao.metas && acao.metas.length)) {
              acao.periodosApuracao = 'UNICA_VEZ';
              acao.valor = 0;
              acao.metas = [];
              acao.valorBonificacao = 0;
              acao.formaBonificacao = '';
            }
          }
        });
      });
    },
    formatarAcoesCategoriaPrincipal(categoria) {
      const categoriaOriginal = this.listaCategorias.find((cat) => cat.id === categoria.id);
      categoria.acoes.forEach((acao) => {
        if ((!!acao.valorBonificacao && acao.valorBonificacao > 0)
          || (!!acao.metas && acao.metas.length)
          || (this.indSemVerba)) {
          if (this.indSemVerba) {
            acao.periodosApuracao = 'UNICA_VEZ';
          }
          const camposComuns = this.$refs.camposComuns.getObjetoCamposComuns();
          const {
            dtaFim,
            dtaInicio,
            formaBonificacao,
            id,
            idAcaoTipo,
            idCategoria,
            idConfiguracao,
            idContratoTipo,
            idsFamilias,
            idsProdutosObrigatorios,
            idsProdutosExcecao,
            mapaCamposDinamicos,
            mapaExtensoes,
            metas,
            meta,
            nomeAcaoTipo,
            periodosApuracao,
            status,
            valor,
            valorBonificacao,
          } = acao;
          const acaoAdicionada = {
            descricao: categoriaOriginal.nomeCategoria.concat(' - ').concat(acao.nomeAcaoTipo),
            dtaFim,
            dtaInicio,
            fornecedores: [...categoria.fornecedores],
            formaBonificacao,
            id,
            idAcaoTipo,
            idCategoria,
            idConfiguracao,
            idContratoTipo,
            idsCategoriasAgrupadas: categoria.idsCategoriasAgrupadas.toString(),
            idsFamilias,
            mapaCamposDinamicos,
            mapaExtensoes,
            metas,
            meta,
            nomeAcaoTipo,
            periodosApuracao,
            status,
            valor,
            valorBonificacao,
            ...camposComuns,
          };
          if (acao.indAcaoAlterada || camposComuns.indAcaoAlterada) {
            acaoAdicionada.indAcaoAlterada = true;
          }
          if (acao.divisao && acao.divisao.id) {
            acaoAdicionada.idDivisao = acao.divisao.id;
          }
          if (acao.unidadeNegocio && acao.unidadeNegocio.id) {
            acaoAdicionada.idUnidadeNegocio = acao.unidadeNegocio.id;
          }
          if (acao.fornecedor && acao.fornecedor.id) {
            acaoAdicionada.idFornecedor = acao.fornecedor.id;
          }
          if (acao.grupoFornecedor && acao.grupoFornecedor.id) {
            acaoAdicionada.idGrupoFornecedor = acao.grupoFornecedor.id;
          }
          if (acao.configuracao.configuracao.passo5.apuracao.template === 'META_POR_QUANTIDADE') {
            acaoAdicionada.calculoApuracao = 'QUANTIDADE';
          }
          if (acao.idsFamilias && acao.idsFamilias.length) {
            const formataIdsFamilia = Array.isArray(acao.idsFamilias) && acao.idsFamilias.some((item) => typeof item === 'object');
            if (formataIdsFamilia) {
              acao.idsFamilias = acao.idsFamilias.map((item) => item.id);
              acaoAdicionada.idsFamilias = acao.idsFamilias;
            }
          }
          if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
            const {
              calculoApuracao,
              calculoBonificacao,
              idCopia,
            } = acao;

            acaoAdicionada.dtaInicioFormatada = formatDateDDMMYYYY(dtaInicio);
            acaoAdicionada.dtaFimFormatada = formatDateDDMMYYYY(dtaFim);
            acaoAdicionada.calculoApuracao = calculoApuracao;
            acaoAdicionada.calculoBonificacao = calculoBonificacao;
            acaoAdicionada.idCopia = idCopia;
            acaoAdicionada.idsProdutosObrigatorios = idsProdutosObrigatorios;
            acaoAdicionada.idsProdutosExcecao = idsProdutosExcecao;
          }
          if (acaoAdicionada.metas && acaoAdicionada.metas.length) {
            acaoAdicionada.metas.forEach((m) => {
              delete m.id;
              delete m.index;
            });
          }

          this.acoes.push(acaoAdicionada);
          if (!this.indSemVerbaConfiguracaoInicial) {
            this.indAlteracaoValorAcaoSemSalvar = true;
          }
        }
        if (((!acao.valorBonificacao && (!acao.metas || !acao.metas.length))
         || (this.indVerificaMudancaFlagFalse && !this.indAlteracaoValorAcaoSemSalvar)
         || (this.indSemVerba)) && acao.id) {
          this.acoesRemovidas.push(acao.id);
        }
        this.indAlteracaoValorAcaoSemSalvar = false;
        if (categoria.idsCategoriasRemovidas && categoria.idsCategoriasRemovidas.length) {
          categoria.idsCategoriasRemovidas.forEach((idCategoriaRemovida) => {
            const categoriaRemovida = this.listaCategorias.find((cat) => cat.id === idCategoriaRemovida);
            categoriaRemovida.acoes.forEach((acaoRemovida) => {
              if (acaoRemovida.id && !this.acoesRemovidas.includes(acaoRemovida.id)) {
                this.acoesRemovidas.push(acaoRemovida.id);
              }
            });
          });
          categoria.idsCategoriasRemovidas = [];
        }
      });
      this.indVerificaMudancaFlagFalse = false;
      if (this.indSemVerbaConfiguracaoInicial !== this.indSemVerba || this.indSemVerba) {
        this.acoes.forEach((itemAcao) => {
          if (itemAcao.id) {
            delete itemAcao.id;
          }
        });
      }
      this.indSemVerbaConfiguracaoInicial = false;
    },
    formatarAcoesCategoriaAgrupada(idCategoriaAgrupada, categoriaPrincipal) {
      const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === idCategoriaAgrupada);
      if (!categoriaAgrupada.acoes) {
        categoriaAgrupada.acoes = categoriaPrincipal.acoes;
      }

      categoriaPrincipal.acoes.forEach((acao) => {
        categoriaAgrupada.acoes.forEach((acaoAgrupada) => {
          if (acao.idAcaoTipo === acaoAgrupada.idAcaoTipo
            && ((!acao.idCopia && !acaoAgrupada.idCopia) || acao.idCopia === acaoAgrupada.idCopia)) {
            if ((!!acao.valorBonificacao && acao.valorBonificacao > 0)
              || (!!acao.metas && acao.metas.length) || (this.indSemVerba)) {
              const camposComuns = this.$refs.camposComuns.getObjetoCamposComuns();
              const {
                dtaFim,
                dtaInicio,
                formaBonificacao,
                idAcaoTipo,
                idConfiguracao,
                idContratoTipo,
                idsFamilias,
                idsProdutosObrigatorios,
                idsProdutosExcecao,
                mapaCamposDinamicos,
                mapaExtensoes,
                metas,
                meta,
                nomeAcaoTipo,
                periodosApuracao,
                status,
                valor,
                valorBonificacao,
              } = acao;
              const acaoAdicionada = {
                descricao: categoriaAgrupada.nomeCategoria.concat(' - ').concat(acao.nomeAcaoTipo),
                dtaFim,
                dtaInicio,
                formaBonificacao,
                id: acaoAgrupada.id,
                idAcaoTipo,
                idCategoria: categoriaAgrupada.id,
                idConfiguracao,
                idContratoTipo,
                idsFamilias,
                idsCategoriasAgrupadas: categoriaPrincipal.idsCategoriasAgrupadas.toString(),
                mapaCamposDinamicos,
                mapaExtensoes,
                metas,
                meta,
                nomeAcaoTipo,
                periodosApuracao,
                status,
                valor,
                valorBonificacao,
                fornecedores: [...categoriaPrincipal.fornecedores],
                ...camposComuns,
              };
              if (acao.indAcaoAlterada || camposComuns.indAcaoAlterada) {
                acaoAdicionada.indAcaoAlterada = true;
              }
              if (acao.divisao && acao.divisao.id) {
                acaoAdicionada.idDivisao = acao.divisao.id;
              }
              if (acao.unidadeNegocio && acao.unidadeNegocio.id) {
                acaoAdicionada.idUnidadeNegocio = acao.unidadeNegocio.id;
              }
              if (acao.fornecedor && acao.fornecedor.id) {
                acaoAdicionada.idFornecedor = acao.fornecedor.id;
              }
              if (acao.grupoFornecedor && acao.grupoFornecedor.id) {
                acaoAdicionada.idGrupoFornecedor = acao.grupoFornecedor.id;
              }
              if (acao.configuracao.configuracao.passo5.apuracao.template === 'META_POR_QUANTIDADE') {
                acaoAdicionada.calculoApuracao = 'QUANTIDADE';
              }
              if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
                const {
                  calculoApuracao,
                  calculoBonificacao,
                  idCopia,
                } = acao;

                acaoAdicionada.dtaInicioFormatada = formatDateDDMMYYYY(dtaInicio);
                acaoAdicionada.dtaFimFormatada = formatDateDDMMYYYY(dtaFim);
                acaoAdicionada.calculoApuracao = calculoApuracao;
                acaoAdicionada.calculoBonificacao = calculoBonificacao;
                acaoAdicionada.idCopia = idCopia;
                acaoAdicionada.idsProdutosObrigatorios = idsProdutosObrigatorios;
                acaoAdicionada.idsProdutosExcecao = idsProdutosExcecao;
              }
              if (acaoAdicionada.metas && acaoAdicionada.metas.length) {
                acaoAdicionada.metas.forEach((m) => {
                  delete m.id;
                  delete m.index;
                });
              }

              this.acoes.push(acaoAdicionada);
              if (!this.indSemVerbaConfiguracaoInicial) {
                this.indAlteracaoValorAcaoSemSalvar = true;
              }
            }
            if (((!acao.valorBonificacao && (!acao.metas || !acao.metas.length) && acaoAgrupada.id)
            || (this.indVerificaMudancaFlagFalse && acao.id && !this.indAlteracaoValorAcaoSemSalvar)
            || (this.indSemVerba)) && acao.id) {
              this.acoesRemovidas.push(acaoAgrupada.id);
            }
            this.indAlteracaoValorAcaoSemSalvar = false;

            if (categoriaAgrupada.idsCategoriasRemovidas && categoriaAgrupada.idsCategoriasRemovidas.length) {
              categoriaAgrupada.idsCategoriasRemovidas.forEach((idCategoriaRemovida) => {
                const categoriaRemovida = this.listaCategorias.find((cat) => cat.id === idCategoriaRemovida);
                categoriaRemovida.acoes.forEach((acaoRemovida) => {
                  if (acaoRemovida.id && !this.acoesRemovidas.includes(acaoRemovida.id)) {
                    this.acoesRemovidas.push(acaoRemovida.id);
                  }
                });
              });
              categoriaAgrupada.idsCategoriasRemovidas = [];
            }
          }
        });
      });
      if (this.indSemVerbaConfiguracaoInicial !== this.indSemVerba || this.indSemVerba) {
        this.acoes.forEach((itemAcao) => {
          if (itemAcao.id) {
            delete itemAcao.id;
          }
        });
      }
      this.indSemVerbaConfiguracaoInicial = false;
    },
    qtdeAcoes() {
      return this.acoes.length;
    },
    somarValorContrato() {
      if (this.isTemplateSomaValorMonetarioAcoes && !this.contratoAprovado) {
        if (this.qtdeAcoes() === 0) {
          this.objetoPassoGeralContrato.valor = 0;
        } else if (this.qtdeAcoes() === 1) {
          this.objetoPassoGeralContrato.valor = this.acoes[0].valor;
        } else {
          const valores = this.acoes.map((a) => a.valor);
          this.objetoPassoGeralContrato.valor = valores.reduce((a, b) => a + b, 0);
        }
        this.$emit('PlanejamentoContratoFormAcoesControle__setValorContrato',
          this.objetoPassoGeralContrato.valor);
      }
    },
    verificaSeValidacaoDuplicidadeContratoHabilitada() {
      this.planejamentoContratoResource.verificaSeValidacaoDuplicidadeContratoHabilitada()
        .then((res) => {
          this.isValidacaoDuplicidadeHabilitada = res.data;
        });
    },
    removerAcao(idAcao) {
      this.acoesRemovidas.push(idAcao);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.resumo.montaResumo();
    });
    this.verificaSeValidacaoDuplicidadeContratoHabilitada();
    this.carregaListaCategorias().then(() => {
      this.montaControleDeCategoria();

      if (!this.novoContrato) {
        this.carregaCategoriasEdicaoContrato();
      }
      function compare(a, b) {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      this.listaCategorias.sort(compare);
      this.paginaCarregada = true;
    });
  },
};
</script>
<style lang="scss">
.PlanejamentoContratoFormAcoesControle__Dica {
  padding: 10px;
  span {
    font-size: 14px;
  }
}
.PlanejamentoContratoFormAcoesControle__adicionar_contrato_btn {
  bottom: 62px !important;
}
.PlanejamentoContratoFormAcoesControle__categoria_duplicada_btn {
  color: rgb(63, 117, 244);
}
</style>

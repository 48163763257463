export default {
  computed: {
    periodosApuracaoPermitidos() {
      const { periodosApuracao } = this.configuracaoAcao;
      return this.listaPeriodosApuracao
        .filter((p) => periodosApuracao.indexOf(p.valor) >= 0);
    },
    calculosBonificacoesPermitidos() {
      const { calculosBonificacao } = this.configuracaoAcao;
      return this.listaCalculosBonificacoes
        .filter((p) => calculosBonificacao.indexOf(p.valor) >= 0);
    },
    temCalculosBonificacoesPermitidos() {
      return this.calculosBonificacoesPermitidos
        && this.calculosBonificacoesPermitidos.length;
    },
    formasBonificacaoPermitidas() {
      const { bonificacoes } = this.configuracaoAcao;
      return this.listaFormaBonificacao
        .filter((p) => bonificacoes.indexOf(p.valor) >= 0);
    },
    basesCalculoPermitidas() {
      const { basesCalculo } = this.configuracaoAcao;
      return this.listaBaseCalculo
        .filter((p) => basesCalculo.indexOf(p.valor) >= 0);
    },
    temBasesCalculoPermitidas() {
      return this.basesCalculoPermitidas
        && this.basesCalculoPermitidas.length;
    },
    calculosApuracoesPermitidos() {
      const { calculosApuracao } = this.configuracaoAcao;
      return this.listaCalculoApuracao
        .filter((p) => calculosApuracao.indexOf(p.valor) >= 0);
    },
    temCalculosApuracoes() {
      return this.calculosApuracoesPermitidos
        && this.calculosApuracoesPermitidos.length;
    },
    unidadesMedidasPermitidas() {
      const { unidadesMedidas } = this.configuracaoAcao;
      return unidadesMedidas;
    },
    tiposPagamentoPermitidos() {
      const { pagamentos } = this.configuracaoAcao;
      return this.tiposPagamento
        .filter((p) => pagamentos.indexOf(p.id) >= 0)
        .map((p) => this.listaTipoPagamento.filter((t) => t.valor === p.codTipo)[0]);
    },
  },
  data() {
    return {
      produtoResource: this.$api.produto(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredAny: (val) => {
          const msg = this.$t('message.campo_obrigatorio');
          return (val && val.length && val.length > 0) || msg;
        },
        requiredValor: (val, metaNaoAplica, metaPorQuantidade, verbaFixa) => [
          () => {
            if ((!metaNaoAplica && !metaPorQuantidade) || !verbaFixa) {
              return true;
            }

            const msg = this.$t('message.campo_obrigatorio');
            return (!!val && val > 0) || msg;
          },
        ],
      },
      listaPeriodosApuracao: [
        {
          valor: 'ANUAL',
          nome: this.$tc('label.anual'),
        },
        {
          valor: 'SEMESTRAL',
          nome: this.$tc('label.anual', 1),
        },
        {
          valor: 'TRIMESTRAL',
          nome: this.$tc('label.trimestral'),
        },
        {
          valor: 'MENSAL',
          nome: this.$tc('label.mensal'),
        },
        {
          valor: 'UNICA_VEZ',
          nome: this.$tc('label.unica_vez'),
        },
      ],
      listaCalculosBonificacoes: [
        {
          valor: 'SELLIN',
          nome: this.$tc('label.sellin'),
        },
        {
          valor: 'SELLOUT',
          nome: this.$tc('label.sellout'),
        },
      ],
      listaBaseCalculo: [
        {
          valor: 'VALOR_BRUTO',
          nome: this.$tc('label.valor_bruto'),
        },
        {
          valor: 'VALOR_LIQUIDO',
          nome: this.$tc('label.valor_liquido'),
        },
        {
          valor: 'NOTAS_DEVOLUCAO',
          nome: this.$tc('label.nota_devolucao', 2),
        },
      ],
      listaFormaBonificacao: [
        {
          valor: 'FIXO',
          nome: this.$tc('label.fixo'),
        },
        {
          valor: 'VARIAVEL',
          nome: this.$tc('label.variavel'),
        },
        {
          valor: 'PERCENTUAL',
          nome: this.$tc('label.percentual'),
        },
      ],
      listaTipoPagamento: [
        {
          valor: 'DESCONTO_NOTA',
          nome: this.$tc('label.desconto_nota'),
        },
        {
          valor: 'DEPOSITO_CONTA',
          nome: this.$tc('label.deposito_conta'),
        },
        {
          valor: 'BONIFICACAO_PRODUTOS',
          nome: this.$tc('label.bonificacao_produtos'),
        },
        {
          valor: 'CREDITO',
          nome: this.$tc('label.credito'),
        },
        {
          valor: 'ENCONTRO_CONTAS',
          nome: this.$tc('label.encontro_contas'),
        },
        {
          valor: 'BOLETO',
          nome: this.$tc('label.boleto'),
        },
        {
          valor: 'AVISO_CREDITO',
          nome: this.$tc('label.aviso_credito'),
        },
        {
          valor: 'DOACAO',
          nome: this.$tc('label.doacao'),
        },
      ],
      listaCalculoApuracao: [
        {
          valor: 'SELLIN_VALOR',
          nome: this.$tc('label.sellin_valor'),
        },
        {
          valor: 'SELLIN_VOLUME',
          nome: this.$tc('label.sellin_volume'),
        },
        {
          valor: 'SELLOUT_VALOR',
          nome: this.$tc('label.sellout_valor'),
        },
        {
          valor: 'SELLOUT_VOLUME',
          nome: this.$tc('label.sellout_volume'),
        },
      ],

      sigla: '',
      metas: [],
      descontos: [],
      tiposPagamento: [],

      dialog: {
        message: '',
        agree: () => {},
        disagree: () => {},
      },

      destacarMeta: false,

      formaBonificacaoAnterior: null,

      parametrosAplicados: false,
      metadadosParametrizado: null,
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.containerExcecao.getValoresDependencias(),
                };
                return this.produtoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nomProduto',
            },
          },
        ],
      },
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: true,
        md6: true,
      },
    };
  },
  methods: {
    buscarTiposPagamento() {
      return this.tipoBeneficioResources
        .listarAtivos()
        .then((response) => {
          this.tiposPagamento = response.data;
        });
    },
  },
};

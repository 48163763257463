import PlanejamentoContratoFormAcoes from '@/spa/planejamento-contrato/form/passo-acoes/PlanejamentoContratoFormAcoes';
import PlanejamentoContratoFormApuracao from '@/spa/planejamento-contrato/form/passo-apuracao/PlanejamentoContratoFormApuracao';
import PlanejamentoContratoFormCampos from './passo-geral/PlanejamentoContratoFormCampos';
import PlanejamentoContratoFormAcoesCartaParceria from './passo-acoes-carta-parceria/PlanejamentoContratoFormAcoes';

const componenteInclusaoAcao = (configuracao) => {
  const { passo2 } = configuracao;
  if (passo2.tipoInclusaoAcao === 'CARTA_PARCERIA'
    || passo2.tipoInclusaoAcao === 'CARTA_PARCERIA_SAMSUNG' || passo2.tipoInclusaoAcao === 'CARTA_PARCERIA_NIVEL_FAMILIA' || passo2.tipoInclusaoAcao === 'CARTA_PARCERIA_EPOCA') {
    return 'planejamento-contrato-form-acoes-carta-parceria';
  }
  return 'planejamento-contrato-form-acoes';
};

export default {
  componentes: {
    PlanejamentoContratoFormCampos,
    PlanejamentoContratoFormAcoes,
    PlanejamentoContratoFormApuracao,
    PlanejamentoContratoFormAcoesCartaParceria,
  },
  passosHabilitados(ref, configuracao, contrato) { // eslint-disable-line
    return {
      geral: {
        habilitado: true,
        label: ref.$tc('label.geral', 1),
        componente: 'planejamento-contrato-form-campos',
      },
      acoes: {
        habilitado: true,
        label: ref.$tc('label.acao', 2),
        componente: componenteInclusaoAcao(configuracao),
      },
      apuracao: {
        habilitado: false,
        label: null,
        componente: 'planejamento-contrato-form-apuracao',
      },
      simulador: {
        habilitado: false,
        label: null,
      },
    };
  },
};
